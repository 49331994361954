.section-toolboxes-banner {
    background-image: url('/src/components/media/plainbackground.jpg');
    background-size: cover;
    padding: 5% 0;
}


.section-toolboxes-banner .info-holder {
    padding: 35px;
}

.section-toolboxes-banner .row {
  display: flex;
  align-items: center;
}
.section-toolboxes-banner .mrt-subtitle {
  color: white;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0;
  letter-spacing: 3px;
  line-height: 1em;
}
.section-toolboxes-banner .mrt-title {
  padding: 0;
  font-size: 50px;
  color: white;
  margin: 0;
  line-height: 1em;
  font-weight: 900;
  letter-spacing: 3px;
  margin-bottom: 35px;
  text-align: left;
}

.section-toolboxes-banner .mrt-desc {
    font-size: 12px;
    font-weight: 300;
    color: white;
    padding-left: 100px;
    line-height: 24px;
    letter-spacing: 1px;
    position: relative;
    margin-bottom: 35px;
}

.section-toolboxes-banner .mrt-desc:before {
    content: '';
    width: 75px;
    height: 1px;
    position: absolute;
    top: 8px;
    left: 0;
    background-color: #99cc33;
}

.section-toolboxes-banner .mrt-title .bigger {
  display: block;
  font-size: 1.37em;
}

@media (min-width: 1212px) {
    .section-toolboxes-banner .container {
        width: 1212px;
    }
}


@media only screen and (max-width: 765px) {

    .section-toolboxes-banner .section-mrt-blocks .mrt-row .mrt-col {
        width: 100% !important;
    }
    .section-toolboxes-banner .section-mrt-blocks .mrt-row {
        flex-flow: column;
    }

    .section-toolboxes-banner .mrt-subtitle {
        font-size: calc(100vw * 14/390);
    }
    .section-toolboxes-banner .mrt-title {
        font-size: calc(100vw * 34/390);
    }


    .section-toolboxes-banner    .mrt-desc {
        border-style: solid none none none;
        border-top-color: #99cc33;
        border-width: calc(100vw * 1/390);
        padding-top: calc(100vw * 1/390);
        font-size: calc(100vw * 12/390);
        font-weight: 300;
        color: white;
        padding-left: 0px;
        line-height: calc(100vw * 24/390);
        letter-spacing: calc(100vw * 1/390);
        position: relative;
        margin-bottom: calc(100vw * 35/390);
    }
    .section-toolboxes-banner .mrt-desc:before {
        display:none
    }
}