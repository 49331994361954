#bg-banner .five .slide-holder {
    height: calc(100vw * 545/1900);
	overflow: hidden;
}
#bg-banner .five .cust {
	display: flex;
	align-items: center;
	justify-content: center;
}
#bg-banner .five .container {
	padding: 0 0!important;
}
#bg-banner .five .content-holder {
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;
}
#bg-banner .five .text-holder {
	display: flex;
	align-items: center;
	flex-direction: column;
}
#bg-banner .five .video-container {
	position: relative;
    left: calc(100vw * -129/1900);
}
#bg-banner .five .video-container video { 
	width: calc(100vw * 1122/1900);
    height: calc(100vw * 541/1900);
}
#bg-banner .five .col-50 .message {
	margin: calc(100vw * 30/1900) calc(100vw * 10/1900);
	display: flex;
	align-items: flex-start;
	flex-direction: column;
} 
#bg-banner .five .text-holder p.sign {
	color: #9C3;
	text-align: justify;
	font-family: Brightside;
	font-size: calc(100vw * 60/1900);
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-align: left;
}
#bg-banner .five .text-holder h1 {
	color: #9C3;
	text-align: center;
	font-family: Montserrat;
	font-size: calc(100vw * 100/1900);
	font-style: normal;
	font-weight: 800;
	line-height: 110.7%;
	text-transform: uppercase;
	letter-spacing: 0.8px;
	margin: calc(100vw * 30/1900) 0 calc(100vw * 10/1900);
}
#bg-banner .five .text-holder span {
	color: #FFF;
	text-align: center;
	font-family: Montserrat;
	font-size: calc(100vw * 32/1900);
	font-style: normal;
	font-weight: 700;
	line-height: 110.7%;
	letter-spacing: calc(100vw * 3.2/1900);
	padding: calc(100vw * 35/1900) 0;
	border-top: 1px solid #9C3;
}
#bg-banner .five .text-holder p {
	color: #FFF;
	text-align: justify;
	font-family: Montserrat;
	font-size: calc(100vw * 21/1900);
	font-style: normal;
	font-weight: 400;
	line-height: calc(100vw * 32/1900);
}
#bg-banner .five .text-holder span img {
	width: calc(100vw * 214/1900);
}
#bg-banner .five .image-holder .gallery-item img {
	width: calc(100vw * 300/1900);
    height: calc(100vw * 300/1900);
    object-fit: cover;
    object-position: bottom;
}
#bg-banner .five .image-holder {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 4px;
	margin: 36px 0 0 30px;
}

#bg-banner .five .full .image-holder img {
	max-width: calc(100vw * 229/1900);
	min-width: calc(100vw * 229/1900);
}
#bg-banner .five a span.learn-more {
	color: #9C3;
	font-size: calc(100vw * 18/1900);
	font-weight: 700;
	display: inline-flex;
	align-items: center;
	letter-spacing: normal;
	border-top: 0px solid #9C3;
}
#bg-banner .five a span.learn-more img {
	width: calc(100vw * 18/1900);
}


@media only screen and ( max-width: 765px ) {

	#bg-banner {
        height: calc(100vw* 623 / 599);
    }
	#bg-banner .five .slide-holder {
		height: calc(100vw * 545/390);
	}
	#bg-banner .five .logo-holder img {
		width: calc(100vw * 238/390);
		height: auto;
	}
	#bg-banner .five .map-bg {
		height: calc(100vw * 225/390);
		width: 100%;
	}
	#bg-banner .five .col-50 .message {
		margin: calc(100vw * 30/390) calc(100vw * 10/390);
	}
	#bg-banner .five .text-holder p.sign {
		font-size: calc(100vw * 32/390);
		display: block;
		width: 100%;
		border-bottom: calc(100vw * 1/390) solid #9C3;
		padding-bottom: calc(100vw * 25/390);
	}
	#bg-banner .five .text-holder h1 {
		font-size: calc(100vw * 44/390);
		margin: calc(100vw * 10/390) 0 calc(100vw * 10/390);
		padding: 0;
	}
	#bg-banner .five .text-holder span {
		font-size: calc(100vw * 14/390);
		letter-spacing: calc(100vw * 1/390);
		padding: calc(100vw * 15/390) 0;
		max-width: calc(100vw * 255/390);
	}
	#bg-banner .five .video-container {
		position: unset;
		left: unset;
		text-align: center;
	}
	#bg-banner .five .video-container video { 
		width: auto;
		height: calc(100vw * 145/390);
		margin: auto;
	}
	#bg-banner .five .text-holder p {
		font-size: calc(100vw * 14/390);
		line-height: calc(100vw * 21/390);
	}
	#bg-banner .five .text-holder span img {
		width: calc(100vw * 103/390);
	}
	#bg-banner .five .full .image-holder img {
		max-width: calc(100vw * 229/390);
		min-width: calc(100vw * 229/390);
	}
	#bg-banner .five a span.learn-more {
		font-size: calc(100vw * 8/390);
	}
	#bg-banner .five a span.learn-more img {
		width: calc(100vw * 12/390);
	}


}