#section-info {
    background-color: #272727;
}
#section-info .info-holder .icon img {
    width: auto;
    height: calc(100vw* 60 / 1900);
}
#section-info .info-holder {
    display: flex;
    gap: calc(100vw* 20 / 1900);
    padding: calc(100vw* 23 / 1900) calc(100vw* 15 / 1900);
}
#section-info .info-holder .info .title {
    margin: 0 0 calc(100vw* 5 / 1900);
    font-size: calc(100vw* 21 / 1900);
    font-weight: 500;
    line-height: 1.25;
    text-transform: none;
    color: white;
}
#section-info .info-holder .info .desc {
    margin: 0;
    font-size: calc(100vw* 18 / 1900);
    font-weight: 500;
    text-transform: none;
    cursor: pointer;
    color: white;
    letter-spacing: 1px;
    position: relative;
}