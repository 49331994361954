.section-mrt-blocks, .section-mrt-blocks .info-holder {
    position: relative;
}

#section-chassis-mount-for-initiator .container {
    padding: calc(100vw* 50 / 1900) 0;
    width: calc(100vw* 1600 / 1900);
}

#section-chassis-mount-for-initiator .mrt-row {
    align-items: flex-start;
}

#section-chassis-mount-for-initiator .mrt-row h1 {
    font-family: var(--font-family-default);
    font-style: normal;
    font-weight: 800;
    font-size: calc(100vw* 80 / 1900);
    line-height: calc(100vw* 90 / 1900);
    letter-spacing: -0.02em;
    color: #FFFFFF;
    text-align: center;
}

#section-chassis-mount-for-initiator .mrt-row h1 img {
    width: calc(100vw* 343 / 1900);
}

#section-chassis-mount-for-initiator .mrt-row .cust-text-header {
    color: #0d0d0d;
    background: #FFF;
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-stroke: calc(100vw* 3 / 1900) transparent;
}

#section-chassis-mount-for-initiator .mrt-row {
    align-items: flex-start;
}

#section-chassis-mount-for-initiator .list-holder .mrt-row {
    align-items: start;
}


#section-chassis-mount-for-initiator .list {
    list-style: none;
    padding: 0 calc(100vw* 5 / 1900) 0 0;
    font-size: calc(100vw* 14 / 1900);
    margin-top: calc(100vw* 15 / 1900);
    height: calc(100vw* 638 / 1900);
}

#section-chassis-mount-for-initiator .list span {
    font-family: var(--font-family-default);
    font-style: normal;
    font-weight: 900;
    font-size: calc(100vw* 18 / 1900);
    line-height: calc(100vw* 14 / 1900);
    text-transform: uppercase;
    color: #FFFFFF;
}

#section-chassis-mount-for-initiator .list .item {
    font-family: var(--font-family-default);
    font-style: normal;
    font-weight: 400;
    font-size: calc(100vw* 16 / 1900);
    line-height: calc(100vw* 26 / 1900);
    color: #fff;
    margin: calc(100vw* 10 / 1900) 0;
    z-index: 1;
    position: relative;
    max-width: calc(100vw* 430 / 1900);
}


#section-chassis-mount-for-initiator .list .green-caps {
    font-family: var(--font-family-default);
    font-style: normal;
    font-weight: 900;
    font-size: calc(100vw* 14 / 1900);
    line-height: calc(100vw* 14 / 1900);
    text-transform: uppercase;
    color: #9C3;
    margin: 0;
    margin-top: calc(-1* 100vw* 10 / 1900);
}

#section-chassis-mount-for-initiator p.from-price {
    margin-bottom: 0px;
    font-family: var(--font-family-default);
    font-style: normal;
    font-weight: 900;
    font-size: calc(100vw* 31.5 / 1900);
    line-height: calc(100vw* 23 / 1900);
    text-transform: uppercase;
    color: #FFFFFF;
}

#section-chassis-mount-for-initiator p.from-price span {
    font-weight: 600;
    font-size: calc(100vw* 30 / 1900);
}

#section-chassis-mount-for-initiator p.current-price {
    font-family: var(--font-family-default);
    font-style: normal;
    font-weight: 900;
    font-size: calc(100vw* 56 / 1900);
    line-height: calc(100vw* 60 / 1900);
    text-transform: uppercase;
    color: #FFFFFF;
}

#section-chassis-mount-for-initiator > .container > div:nth-child(2) .button {
    border: none;
}
#section-chassis-mount-for-initiator .button-holder .button {
    font-weight: 600;
    font-size: calc(100vw* 17 / 1900);
    line-height: calc(100vw* 21 / 1900);
    text-align: center;
    color: black;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    padding: calc(100vw* 15 / 1900);
    display: inline-block;
    width: calc(100vw* 230 / 1900);
}

#section-chassis-mount-for-initiator .img-holder {
    position: relative;
}

#section-chassis-mount-for-initiator .img-holder video {
    width: calc(100vw* 1100 / 1900);
    position: relative;
    right: 30%;
}