
#section-gears {
    padding: calc(100vw* 35 / 1900) 0;
}
#section-gears h2 {
    color: #D9D9D9;
    font-size: calc(100vw* 70 / 1900);
    font-weight: 900;
    text-align: center;
    margin: calc(100vw* 55 / 1900) 0;
}
#section-gears .row {
    gap: calc(100vw* 25 / 1900);
}
#section-gears .image-holder {
    padding: calc(100vw* 55 / 1900) 0;
}
#section-gears .image-holder img {
    border-radius: calc(100vw* 30 / 1900);
}
#section-gears .title {
    font-size: calc(100vw* 21 / 1900);
    font-weight: 700;
    line-height: 1.2;
    text-transform: none;
    margin: 0;
}
#section-gears a {
    font-size: calc(100vw* 18 / 1900);
    line-height: 1.2;
    text-transform: none;
    margin: 0;
}
#section-gears .img-holder {
    margin-bottom: calc(100vw* 20 / 1900);
}
#section-gears .img-holder img {
    width: auto;
    height: calc(100vw* 50 / 1900);
}
#section-gears ul {
    list-style: none;
    padding: 0;
    font-size: calc(100vw* 16 / 1900);
    line-height: 1.2;
}
#section-gears ul li {
    position: relative;
    padding-left: calc(100vw* 20 / 1900);
}
#section-gears ul li:before {
    content: "■";
    color: var(--color-mrt-green);
    display: inline-block;
    position: absolute;
    left: 0;
}