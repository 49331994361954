#section-six {
	padding: calc(100vw * 100/1900) 0;
	/* 	background-image: url('https://www.mrt.com.au/wp-content/uploads/2023/04/mrt-home-page-explore-bound-1-scaled.jpg'); */
    background-image: url('/src/components/media/mrt-home-page-explore-bound-1-scaled-1-compressed.jpg');

	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
#section-six .content-holder .img-holder {
	width: calc(100vw * 87.3/1900);
}

#section-six .content-holder .img-holder .desktop {
	display: block;
}
#section-six .content-holder .img-holder .mobile {
	display: none;
}
#section-six .content-holder .title {
	margin: 0 0 calc(100vw * 35/1900);
}
#section-six .content-holder .desc {
	font-weight: 400;
	font-size: calc(100vw * 16/1900);
	line-height: calc(100vw * 26/1900);
	text-align: justify;
	letter-spacing: 0.03em;
}
#section-six .content-holder .btn-holder a {
	display: inline-flex;
	line-height: calc(100vw * 35/1900);
	column-gap: calc(100vw * 10/1900);
}
#section-six .content-holder .btn-holder img {
	height: calc(100vw * 35/1900);
	width: calc(100vw * 35/1900);
	display: inline-block;
}
#section-six .content-holder .btn-holder span {
	font-weight: 900;
	font-size: calc(100vw * 24/1900);
	line-height: calc(100vw * 35/1900);
	color: #99CC33;
	display: inline-block;
}



@media only screen and ( max-width: 765px ) {	
	#section-six {
		padding: 25px 0;
		background-size: auto 250px;
		background-position: 60% 0px;
	}
	#section-six .content-holder .img-holder {
		width: 50px;
	}
	

	#section-six .content-holder .title {
		margin: 0 0 15px;
	}
	#section-six .content-holder .title img {
		width: 50%;
	}
	#section-six .content-holder .btn-holder a {
		line-height: 24px;
		column-gap: 5px;
	}
	#section-six .content-holder .btn-holder img {
		height: 24px;
		width: 24px;
	}
	#section-six .content-holder .btn-holder span {
		font-size: 16px;
		line-height: 24px;
	}
	#section-six .container .col.col-60 > .content-holder {
		margin-top: 50px;
	}
	#section-six .content-holder .desc {
		font-size: 14px;
		line-height: 24px;
		margin-bottom: 0;
	}

	#section-six .content-holder .img-holder .desktop {
		display: none;
	}
	#section-six .content-holder .img-holder .mobile {
		display: block;
	}
}