
.image-left-background .container.fullwidth {
  padding: 5% 0;
}
.image-left-background .container.fullwidth .info-holder {
  background-color: transparent;
  box-shadow: none;
  padding-left: 5%;
  padding-right: 5%;
  max-width: 550px;
}

.image-left-background .info-holder .mrt-title {
  font-size: 54px;
  color: white;
  margin: 0;
  line-height: 1em;
  font-weight: 900;
  letter-spacing: 3px;
  margin-bottom: 35px;
  text-align: left;
}

.image-left-background .mrt-desc {
    font-size: 12px;
    font-weight: 300;
    color: white;
    padding-left: 100px;
    line-height: 24px;
    letter-spacing: 1px;
    position: relative;
    margin-bottom: 35px;
}


.image-left-background  .mrt-desc:before {
    content: '';
    width: 75px;
    height: 1px;
    position: absolute;
    top: 8px;
    left: 0;
    background-color: #99cc33;
}

/* button */

.image-left-background  .hvr-ripple-out {
	display: inline-block;
	min-width: 250px;
	font-size: 16px;
	letter-spacing: 3px;
	border: 1px solid #99cc33;
	background-color: rgba(1,6,10,0.65);
	color: #99cc33;
	padding: 12px 20px;
	text-align: center;
	outline: 1px solid;
	outline-offset: -2px;
	text-shadow: none;
	-webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
	-o-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
	transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
	cursor: pointer;
}
.image-left-background  .hvr-ripple-out.green-btn {
	background-color: #99cc33;
	color: white;
}
.image-left-background  .hvr-ripple-out.small {
	min-width: auto;
	letter-spacing: 1px;
	padding: 5px 10px;
	font-size: 12px;
}
.image-left-background  .hvr-ripple-out:before {
	border: none !important;
}
.image-left-background  .hvr-ripple-out:hover {
	outline-color: rgba(255, 255, 255, 0);
	outline-offset: 15px;
}


/* media queries */
@media only screen and (max-width: 765px) {

    .image-left-background .mrt-row {
        flex-direction: column-reverse;
    }
    .image-left-background .info-holder .mrt-title {
        font-size: 36px;
    }
    .image-left-background .mrt-desc {
        border-style: solid none none none;
        border-top-color: #99cc33;
        border-width: 1px;
        padding-top: 10px;
        font-size: 12px;
        font-weight: 300;
        color: white;
        padding-left: 0px;
        line-height: 24px;
        letter-spacing: 1px;
        position: relative;
        margin-bottom: 35px;
    }
    .image-left-background  .mrt-desc:before {
        display:none;
    }

    .image-left-background  .hvr-ripple-out {
        margin-bottom: 55px;
    }

}