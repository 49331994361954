#match .title-holder h2 {
    font-size: calc(100vw * 220/1900);
    letter-spacing: -0.05em;
}

#match .left {
    min-height: calc(100vw* 650 / 1900);
    /* background-image: url(https://www.mrt.com.au/wp-content/uploads/2022/08/dual-cab-complete-tray-for-ute-canopy.jpg); */
    background-image: url(../../media/UteCanopy/dual-cab-complete-tray-for-ute-canopy.jpg);
    background-size: auto 101%;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: calc(100vw* 540 / 1900);
}
#match .right{
    min-height: calc(100vw* 650 / 1900);
    /* background-image: url(https://www.mrt.com.au/wp-content/uploads/2022/09/tray-only-for-ute-canopy.jpg); */
    background-image: url(../../media/UteCanopy/tray-only-for-ute-canopy.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: calc(100vw* 540 / 1900);
}
#match .info-holder {
    text-align: center;
    padding: 0;
}
#match .info-holder h2 {
    font-weight: 700;
    font-size: calc(100vw * 40/1900);
    line-height: 1;
    letter-spacing: -0.03em;
    text-transform: none;
    color: #FFFFFF;
    margin-top: 0;
    margin-bottom: calc(100vw* 22 / 1900);
}

#match .section-tray-uppericons .uppericon {
    max-width: calc(100vw* 80 / 1900);
    margin: 0 calc(100vw* 10 / 1900);
}