#section-nine #section-background {
    position: absolute;
    height: calc(100vw* 360 / 1900);
    width: 100%;
}

#section-nine .container {
    position: relative;
    height: calc(100vw* 360 / 1900);
}

#section-nine .row {
    height: calc(100vw* 360 / 1900);
    align-items: center;
}

#section-nine .title {
    margin: 0;
}

#section-nine .title img {
    height: calc(100vw* 114 / 1900);
    width: auto;
}

@media only screen and (max-width: 765px) {
    #section-nine {
        background-image: url('../media/WHERE-WILL-YOUR-MRT-GEAR-TAKE-YOU_mobileimg.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        padding: 35px 0;
    }

    #section-nine .title img {
        height: auto;
        width: 100%;
    }

    #section-nine .container,
    #section-nine .row {
        height: auto;
    }
}