#brands .container.fullwidth {
    width: 100%;
    padding: 0;
    /* position: relative; */
}

#brands .info-holder .link-holder {
    padding-left: 15%;
    padding-right: 15%;
}

#brands .section-mrt-blocks .info-holder {
    position: relative;
}

#brands .info-holder {
    padding: 35px;
    background-color: rgba(1, 6, 10, 0.75);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}

#brands .info-holder p {
    font-weight: 300;
    font-size: calc(100vw* 30 / 1900);
    line-height: 1.1;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: -1px;
}

#brands .info-holder .link-holder {
    text-align: center;
}

#brands .info-holder .link-holder a {
    display: inline-block;
    min-width: calc(100vw* 250 / 1900);
    text-align: center;
}

#brands .info-holder .link-holder img {
    width: auto;
    height: calc(100vw* 167 / 1900);
}

@media only screen and (max-width: 1211px){
    .section-mrt-blocks .container.fullwidth .info-holder {
        padding: 0 5% !important;
    }
}

@media only screen and (max-width: 820px){
    #brands .info-holder .link-holder a {
        min-width: 240px;
    }
}

@media only screen and (max-width: 498px) {

    #features .title-holder h2,
    #sizes .title-holder h2,
    #options .title-holder h2,
    #accessories .title-holder h2,
    #match .title-holder h2,
    #gallery .title-holder h2,
    #brands .title-holder h2 {
        font-size: 64px;
    }
    #brands .info-holder p {
        font-size: 16px;
    }
    #brands .info-holder .link-holder img {
        height: 165px;
    }
}

@media only screen and (max-width: 450px){
    .info-holder {
        padding: 35px 15px !important;
    }
    .section-mrt-blocks .mrt-row {
        flex-direction: column;
    }
}
