#banner {
    background-image: url(../../media/MRT-Canopy-Banner-for-ute-canopy-291122.jpg);
    background-size: auto 101%;
    background-position: right center;
    background-repeat: no-repeat;
    max-height: calc(100vw* 860 / 1900);
}

#banner #top-nav {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
}

#banner #top-nav .container {
    padding: 0;
}

#banner #top-nav .menu-list-holder .menu-list {
    padding-left: 0;
    margin: calc(100vw* 13 / 1900) 0;
    text-align: center;
}

#banner #top-nav .menu-list-holder .menu-list .menu-item {
    display: inline-block;
    list-style: none;
    padding: 0 10px;
}

#banner #top-nav .menu-list-holder .menu-list .menu-item a {
    color: white;
    font-size: calc(100vw * 16/1900);
}

#banner #top-nav .menu-list-holder .menu-list .menu-item a .hvr-ripple-out {
    font-size: calc(100vw * 14/1900) !important;
    padding: calc(100vw* 11 / 1900) calc(100vw* 25 / 1900) !important;
    line-height: 1;
    min-width: auto !important;
    letter-spacing: 1px !important;
}

#banner > .container {
    padding-top: calc(100vw * (165 / 1900) - 100vw * (64 / 1900));
    padding-bottom: calc(100vw* 351 / 1900);
    padding-right: calc(100vw* 203 / 1900);
    padding-left: calc(100vw* 203 / 1900);
    width: 100%;
}

#banner .info-holder {
    padding: 35px 0;
}

#banner .info-holder h1 {
    font-weight: 700;
    font-size: calc(100vw * 60/1900);
    line-height: calc(100vw * 64/1900);
    letter-spacing: -0.03em;
    color: #FFFFFF;
    text-transform: none;
    text-align: left;
    padding: 0;
    margin-bottom: calc(100vw* 63 / 1900);
}

#banner .info-holder h4 {
    font-weight: 900;
    font-size: calc(100vw * 24/1900);
    line-height: 1.1;
    text-transform: uppercase;
    color: #99CC33;
}

#banner .info-holder .icons-holder a {
    max-width: calc(100vw* 124 / 1900);
    display: inline-block;
    margin-bottom: calc(100vw* 25 / 1900);
    margin-right: calc(100vw* 25 / 1900);
}

#banner .info-holder .icons-holder a img {
    margin-bottom: calc(100vw* 10 / 1900);
}

#banner .info-holder .icons-holder a p {
    font-size: calc(100vw * 16/1900);
    line-height: 1.1;
    text-align: center;
}

@media only screen and (max-width: 1200px) {
    #banner > .container {
      padding-left: 35px;
      padding-right: 35px;
    }
}

@media only screen and (max-width: 820px) {
    #banner .info-holder {
      padding: 35px;
      background-color: rgba(0,0,0,0.35) !important;
    }
}

@media only screen and (max-width: 498px) {
    #banner {
        background-size: auto 37%!important;
        background-position: 95% 0!important;
        max-height: none;
    }
    #banner #top-nav {
      display: none;
    }
    #banner > .container {
        padding-top: 35vh;
    }
    #banner .info-holder {
        padding: 0;
        background-color: transparent;
    }
    #banner .info-holder h1 {
        font-size: 40px;
        line-height: 1.1;
    }
    #banner .info-holder h4 {
        font-size: 18px;
    }
    #banner .info-holder .icons-holder a {
        max-width: 100px;
        margin-bottom: 15px;
        margin-right: 15px;
    }
    #banner .info-holder .icons-holder a p {
        font-size: 10px;
        text-align: center;
    }
  }
