#sec-nav {
    background-color: #F6F6F6;
    position: relative;
    z-index: 9999;
}
#sec-nav .container {
    height: auto;
    margin-right: auto;
    margin-left: auto;
    padding-left: 21px;
    padding-right: 21px;
}

#sec-nav .menu-list-holder .menu-list {
    padding-left: 0;
    margin: calc(100vw * 25/1900) 0;
    text-align: center;
}

#sec-nav .menu-list-holder .menu-list .menu-item {
display: inline-block;
list-style: none;
padding: 0 calc(100vw * 10/1900);
}

#sec-nav .menu-list-holder .menu-list .menu-item a {
    color: black;
    font-weight: 600;
    font-size: calc(100vw * 16/1900);
    line-height: 1;
    letter-spacing: 0.02em;
}

/* .sticky {
    position: fixed !important;
    width: 100%;
    left: 0;
    top: 120px;
    z-index: 100;
    border-top: 0;
} */

@media only screen and (max-width: 768px) {
    #sec-nav {
      background-color: #F6F6F6;
      margin-top: 45px;
    }
    #sec-nav .menu-list-holder .menu-list .menu-item a {
        font-size: 13px !important;
    }
  }

@media only screen and (max-width: 498px) {
    #sec-nav {
      background-color: #F6F6F6;
      margin-top: 0px;
    }
    #sec-nav .menu-list-holder .menu-list .menu-item {
        padding: 0 10px;
    }
  }