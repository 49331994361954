#section-7 {
	padding: calc(100vw * 100/1900);
	background: linear-gradient(180deg, #121212 0%, rgba(255, 255, 255, 0.2) 40%, rgba(255, 255, 255, 0.2) 80%, #121212 100%);
}
#section-7 .container {
	width: calc(100vw * 1600/1900);
}
/* #section-7 .row {
align-items: center;
} */
#section-7 .p-row {
    display: flex;
    width: 100%;
    padding-left: calc(100vw * 50/1900);
    column-gap: calc(100vw * 15/1900);
}
#section-7 .content-holder {
	padding-left: calc(100vw * 50/1900);
}
#section-7 .content-holder .title {
	margin: 0 0 calc(100vw * 15/1900);
}
#section-7 .content-holder .title .desktop {
	max-height: calc(100vw * 100/1900);
	width: auto;
}
#section-7 .content-holder .desc,
#section-7 .p-row .desc {
	font-weight: 400;
	font-size: calc(100vw * 16/1900);
	line-height: calc(100vw * 24/1900);
	text-align: justify;
	letter-spacing: 0.03em;
	margin-bottom: calc(100vw * 10/1900);
}


@media only screen and (max-width: 765px){

    #section-7 {
		padding: 25px 0;
	}
	#section-7 .content-holder {
		padding-left: 0;
	}
	#section-7 .content-holder .title {
		margin: 15px 0;
		text-align: center;
	}
	#section-7 .content-holder .title img {
		width: 100%;
	}
	#section-7 .content-holder .desc,
    #section-7 .p-row .desc {
		font-size: 14px;
		line-height: 24px;
		margin-bottom: 5px;
	}
	#section-7 .p-row {
        flex-direction: column;
    }
}