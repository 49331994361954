#sizes .mrt-row.gradient-bg-rev {
    padding-bottom: 35px;
    align-items: start;
} 

#sizes .info-holder .image-holder {
    margin-bottom: 25px;
    text-align: left;
}

#sizes .info-holder .image-holder img {
    width: auto;
    height: calc(100vw * 350/1900);
}

#sizes .info-holder p {
    font-size: calc(100vw * 16/1900);
}

@media only screen and (max-width: 768px) {
    #sizes .mrt-row {
        flex-direction: column;
    }
     #sizes .mrt-row .mrt-col {
        width: 100%;
    }
}

@media only screen and (max-width: 820px) {
    #sizes .info-holder p {
        font-size: 12px;
        margin-bottom: 35px;
    }
}
@media only screen and (max-width: 498px) {
    #sizes .info-holder .image-holder img {
        width: 100%;
        height: auto;
    }
}