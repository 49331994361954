#section-gallery .video-section .video-holder {
    margin-bottom: calc(100vw* 35 / 1900);
}
#section-gallery .video-section .video-holder iframe {
    width: 100%;
    height: calc(100vw * 950/1900)
}

#section-gallery .col-60 {
	flex: 0 0 60%;
	max-width: 60%;
	max-height: calc(100vw * 760/1900);
	overflow: hidden;
}
#section-gallery .col-40 {
	flex: 0 0 40%;
	max-width: 40%;
    
}

.gallery .gallery-item {
    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 100%;
    cursor: pointer;
    padding: 2px 2px!important;
    position: relative;
    border: none;
}
.gallery-container {
	display: flex;
	flex-wrap: wrap;
}
.main-image img,
.top-image img,
.small-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
    border: none;
}
.main-image img {
	max-width: calc(100vw * 1139/1900);
	max-height: calc(100vw * 760/1900);
	min-width: calc(100vw * 1139/1900);
	min-height: calc(100vw * 760/1900);
}
.top-image img {
	width: calc(100vw * 752/1900);
	height: calc(100vw * 502/1900);
}
.small-image img {
	max-height: calc(100vw * 251/1900);
	object-position: bottom;
}
.more-count {
	position: absolute;
	bottom: 50%;
	right: 50%;
	font-weight: 800;
	font-size: 24px;
}
.col-50:last-child .gallery-item {
	height: 100%;
}
.lightbox-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    text-align: center;
}
.lightbox-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
}
.lightbox-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
.lightbox-container .close-btn,
.lightbox-container .prev-btn,
.lightbox-container .next-btn {
    color: #fff;
    font-size: 34px;
    cursor: pointer;
    background: transparent;
    border: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}
.lightbox-container .close-btn {
    right: 33px;
    top: 15%;
}
.lightbox-container .prev-btn {
    left: 20px;
    bottom: 20px;
}
.lightbox-container .next-btn {
    right: 20px;
    bottom: 20px;
}
.image-count {
    color: #fff;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
}
@media only screen and (max-width: 499px) {
    #mobile {
        display: block;
    }
    #desktop {
        display: none;
    }
    #section-gallery .col-50 {
        width: 50%;
    }
    #section-gallery .row {
        display: flex;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        flex-direction: row;
        flex-wrap: nowrap;
    }
    .main-image img,
    .top-image img,
    .small-image img {
        max-width: calc(100vw * 203/390);
        max-height: 125px;
        min-width: calc(100vw * 203/390);
    }
    .lightbox-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        height: auto;
    }
    .lightbox-image {
        max-width: 100%;
        height: auto;
        object-fit: contain;
    }
    .lightbox-container .prev-btn,
    .lightbox-container .next-btn {
        font-size: 24px;
    }
    .lightbox-container .prev-btn {
        left: 10px;
        bottom: calc(50vh - 20px);
    }
    .lightbox-container .next-btn {
        right: 10px;
        bottom: calc(50vh - 20px);
    }
}
