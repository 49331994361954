#section-package-choice {
    overflow: hidden;
}

#section-package-choice .container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
    display: table;
    content: " ";
}

#section-package-choice .desktop {
    display: block;
}

#section-package-choice .anchored {
    position: relative;
}

.anchored .anchor {
    position: absolute;
    top: calc(-1*(100vw* 200 / 1900));
}
#section-package-choice .container{
    width: var(--section-width-full);
}

#section-package-choice .bg-holder {
    height: calc(100vw* 786.25 / 1900);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
}

#section-package-choice .content-holder h3 {
    margin: 0 0 calc(100vw* 14 / 1900);
}

#section-package-choice .initiator-bg {
    background-image: url(../media/PackageImages/initiatorpackage.jpg);
}

#section-package-choice .explorer-bg {
    background-image: url(../media/PackageImages/explorerpackage.jpg);
}

#section-package-choice .tradesman-bg {
    background-image: url(../media/PackageImages/tradesmanpackage.jpg);
}

#section-package-choice .companion-bg {
    background-image: url(../media/PackageImages/companionpackage.jpg);
}

#section-package-choice .content-holder {
    display: flex;
    position: relative;
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
    z-index: 2;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 100%;
    padding: calc(100vw* 32 / 1900);
}

#section-package-choice .content-holder h3 img {
    width: calc(100vw* 350 / 1900);
    display: block;
    margin: 0 auto;
    transition: all 0.3s ease-in-out;
}

#section-package-choice .content-holder .button-text-holder {
    display: flex;
    flex-direction: column;
    transform: translateY(calc(100vw * 130/1900));
    transition: all 0.3s ease-in-out;
}

#section-package-choice .content-holder .button-text-holder>span {
    color: #FFF;
    font-family: Montserrat;
    font-size: calc(100vw* 32 / 1900);
    font-style: normal;
    font-weight: 400;
    line-height: 2;
    letter-spacing: -0.72px;
}


#section-package-choice .initiator-bg .content-holder a:hover span img,
#section-package-choice .explorer-bg .content-holder a:hover span img,
#section-package-choice .tradesman-bg .content-holder a:hover span img {
    transform: translateX(5px);
}

#section-package-choice .content-holder span img {
    width: calc(100vw* 24 / 1900);
    transition: transform 0.2s ease;
    height: auto;
}

#section-package-choice .initiator-bg::before,
#section-package-choice .explorer-bg::before,
#section-package-choice .tradesman-bg::before,
#section-package-choice .companion-bg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 101%;
    height: 100%;
    background-image: url(../media/PackageImages/shadabove.png);
    background-repeat: no-repeat;
    background-position: 0 0%;
    background-size: 100% 50%;
    z-index: 1;
    opacity: 1;
}

#section-package-choice .initiator-bg::after,
#section-package-choice .explorer-bg::after,
#section-package-choice .tradesman-bg::after,
#section-package-choice .companion-bg::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 101%;
    height: 100%;
    background-image: url(../media/PackageImages/shadow.png);
    background-repeat: no-repeat;
    background-size: 100% 40%;
    background-position: 0 130%;
    z-index: 1;
    opacity: 0.9;
    transition: all 0.3s ease-in-out;
}

#section-package-choice .content-holder:hover .button-text-holder {
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
}

#section-package-choice .content-holder:hover h3 img {
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
}

#section-package-choice .col:hover .bg-holder::after {
    background-position: 0 105%;
    transition: all 0.3s ease-in-out;
}

/* Mobile View */

@media only screen and (max-width: 765px) {
    #section-package-choice .initiator-bg {
        background-position: 0% 100%;
        background-size: calc(100vw* 562 / 390);
    }

    #section-package-choice .bg-holder {
        height: calc(100vw* 600 / 390);
        width: calc(100vw* 390 / 390);
        background-repeat: no-repeat;
        max-height: calc(100vw* 599 / 390);
    }

    #section-package-choice .content-holder h3 img {
        width: calc(100vw* 333 / 390);
        display: block;
        margin: 0 auto;
        height: auto;
    }

    #section-package-choice .content-holder .button-text-holder>span {
        color: #FFF;
        font-family: Montserrat;
        font-size: calc(100vw* 24 / 390);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.72px;
    }

    #section-package-choice .content-holder a span {
        color: #9C3;
        font-family: Montserrat;
        font-size: calc(100vw* 18 / 390);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
        padding: calc(100vw* 10 / 390);
    }
    #section-package-choice .content-holder span img {
        width: calc(100vw* 19 / 390);
    }
    #section-package-choice .content-holder a {
        margin: 0 0 calc(100vw* 14 / 390);
    }
}

@media only screen and (max-width: 575px){
    .swiper-button-next, .swiper-button-prev {
        color: #9C3 !important;
    }   
    #section-package-choice .content-holder .button-text-holder {
        transform: unset!important;
    }
}