.image-holder {
    text-align: center;
}

#section-details .btn-holder button {
    display: block;
    width: 100%;
    min-height: calc(100vw* 130 / 1900);
    border-style: unset;
    border-top: 1px solid #898989;
    background-color: black;
    padding: calc(100vw* 25 / 1900);
    font-size: calc(100vw* 18 / 1900);
    font-weight: 500;
    line-height: 1.1;
    text-align: left;
    color: #5A5A5A;
    display: grid;
}



#section-details .btn-holder button.active {
    border-top: 1px solid var(--color-mrt-green);
    border-bottom: 1px solid var(--color-mrt-green);
}

#section-details .btn-holder button.active .day {
    color: var(--color-mrt-green);
}

#section-details .btn-holder button.active .title {
    color: white;
}

#section-details .details-holder .details {
    padding: calc(100vw* 25 / 1900);
}

#section-details .details-holder .details p {
    font-size: calc(100vw* 16 / 1900);
    line-height: 1.1;
    margin-bottom: calc(100vw* 15 / 1900);
}


#section-details .details-holder .details {
    padding: calc(100vw* 25 / 1900);
}