#options .info-holder h3, #accessories .info-holder h3 {
    font-weight: 900;
    font-size: calc(100vw * 46/1900);
    line-height: 1.1;
    color: #99CC33;
    text-transform: none;
}

#options .image-holder {
    position: relative;
    text-align: left;
}

#options .image-holder img {
    width: 800px !important;
}

#options .image-holder .option {
    position: absolute;
    width: 170px !important;
    text-align: left;
}

#options .image-holder .option img {
    max-width: 50px;
}

#options .image-holder .option.sparewheelcarrier {
    top: 17%;
}

#options .image-holder .option.adjustableladder {
    top: 40%;
}

#options .image-holder .option.jerrycanholder {
    top: 69%;
}

#options .image-holder .option p {
    margin-bottom: 0;
}

#options .image-holder .option a {
    position: absolute;
    right: 0;
    top: 0;
}

#options > div.container.fullwidth > div:nth-child(3) {
    padding: 55px 10%;
}


#section-upgrade-options {
    background: rgb(0,0,0);
    background: linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(56,56,56,1) 100%);
    padding-bottom: 5%;
  }
  #section-upgrade-options.section-mrt-blocks .mrt-row {
    align-items: start;
  }
  #section-upgrade-options .left .holder,
  #options .left .holder {
    min-height: 500px;
    padding-right: 35px;
  }
  #section-upgrade-options .left .holder h5,
  #options .left .holder h5 {
    font-size: 12px;
    padding-top: 58px;
    line-height: 1em;
  }
  #section-upgrade-options .two .left h5,
  #options .two .left h5 {
    font-size: 12px;
    padding-top: 110px;
    line-height: 1em;
  }
  #section-upgrade-options .left .holder img,
  #section-upgrade-options .two .right .holder img,
  #options .left .holder img,
  #options .two .right .holder img {
    width: auto;
    margin-bottom: 10px;
  }
  #section-upgrade-options .two .left .holder img,
  #options .two .left .holder img {
      width: 100%;
  }
  #section-upgrade-options .left .holder p,
  #section-upgrade-options .two .left p,
  #options .left .holder p,
  #options .two .left p {
    margin-bottom: 0;
    line-height: 1em;
  }
  #section-upgrade-options .left .holder .option-holder,
  #section-upgrade-options .two .left .option-holder,
  #options .left .holder .option-holder,
  #options .two .left .option-holder {
    border-top: 1px solid #99cc33;
    padding-top: 15px;
    position: relative;
  }
  #section-upgrade-options .left .holder .option-holder.one,
  #options .left .holder .option-holder.one {
    min-height: 135px;
  }
  #section-upgrade-options .left .holder .option-holder.two,
  #options .left .holder .option-holder.two {
    min-height: 138px;
  }
  #section-upgrade-options .left .holder .option-holder.three,
  #options .left .holder .option-holder.three {
    min-height: 160px;
  }
  #section-upgrade-options .left .holder .option-label,
  #options .left .holder .option-label {
    font-size: 10px;
    color: white;
    display: inline-block;
    position: absolute;
    line-height: 1em;
    left: 20px;
  }

  #options .left .option-more {
    position: absolute;
    top: 0;
    right: 0;
}

  #section-upgrade-options .right,
  #options .right {
    position: relative;
    min-height: 652px;
  }
  #section-upgrade-options .right .holder,
  #options .right .holder {
    /* background: url('https://www.mrt.com.au/staging/wp-content/uploads/2023/02/Canopy-options-side-view-for-ute-canopy-update.png'); */
    background: url('../../media/UteCanopy/Canopy-options-side-view-for-ute-canopy-update.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: 904px;
    height: 652px;
    left: 0;
    top: 12px;
  }
  #section-upgrade-options .right .holder .label,
  #options .right .holder .label {
    font-size: 12px;
    color: white;
    display: inline-block;
    position: absolute;
    line-height: 1em;
  }
  #section-upgrade-options .right .holder .label.solarvent,
  #options .right .holder .label.solarvent {
    top: 60px;
  }
  #section-upgrade-options .right .holder .label.internalsupport,
  #options .right .holder .label.internalsupport {
    top: 195px;
  }
  #section-upgrade-options .right .holder .label.liftoffsystem,
  #options .right .holder .label.liftoffsystem {
    top: 330px;
  }
  #section-upgrade-options .right .holder .label.powdercoatfinish,
  #options .right .holder .label.powdercoatfinish {
    top: 490px;
  }
  #section-upgrade-options .two .right .holder 
  #options .two .right .holder {	
    background: url(https://www.mrt.com.au/staging/wp-content/themes/mrt/img/canopy/upgrade-options/canopy-upgrade-options.png);
  }
  
  #section-upgrade-options .two .right .holder .label.sparewheelcarrier,
  #options .two .right .holder .label.sparewheelcarrier {
    top: 135px;
  }
  #section-upgrade-options .two .right .holder .label.adjustableladder,
  #options .two .right .holder .label.adjustableladder {
    top: 275px;
  }
  #section-upgrade-options .two .right .holder .label.jerrycanholder,
  #options .two .right .holder .label.jerrycanholder {
    top: 430px;
  }
  #section-upgrade-options .right .holder .label span,
  #section-upgrade-options .left .holder p span,
  #options .right .holder .label span,
  #options .left .holder p span {
    color: #99cc33;
    cursor: pointer;
    margin-left: 5px;
    position: relative;
  }
  #section-upgrade-options .left .holder p span,
  #options .left .holder p span {
    margin-left: 0;
  }
  #section-upgrade-options .right .holder .label span i, 
  #section-upgrade-options .left .holder p span i,
  #options .right .holder .label span i, 
  #options .left .holder p span i {
    width: 15px;
    display: inline-block;
    text-align: center;
    border: 1px solid #99cc33;
    border-radius: 50%;
    font-style: normal;
  }
  #section-upgrade-options .right .holder .label span b, 
  #section-upgrade-options .left .holder p span b,
  #options .right .holder .label span b, 
  #options .left .holder p span b {
    display: none;
    position: absolute;
    width: 250px;
    min-height: 100px;
    left: 5px;
    bottom: 5px;
    font-weight: 300;
    padding: 15px;
    background: rgba(153, 204, 51, 0.8);
    box-shadow: 1px 1px 5px rgba(153, 204, 51, 0.9);
    color: white;
    z-index: 2;
  }

  #section-upgrade-options .right .holder .label span:hover b, 
  #section-upgrade-options .left .holder p span:hover b,
  #options .right .holder .label span:hover b, 
  #options .left .holder p span:hover b {
    display: block;
  }
  #section-upgrade-options .option-more,
  #options .option-more {
    font-size: 12px;
  }
  #section-canopy-ladder-racks-anchor,
  #section-canopy-roof-racks-anchor {
    position: absolute;
    top: -150px;
  }
  #section-upgrade-options .left .animation-holder,
  #options .left .animation-holder {
      margin: 35px 0;
      width: 100%;
  }
  #section-upgrade-options .left .animation-holder .hvr-ripple-out,
  #options .left .animation-holder .hvr-ripple-out {
      width: 100%;
  }
  