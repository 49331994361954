.searchbar {
    width: 198px;
    padding: 6px;
    line-height: 30px;
    display: block;
    font-size: 12px;
    position: relative;
    z-index: 2;
    -webkit-appearance: none;
    height: 44px;
    margin: 0 !important;
    border: 1px solid #d8d8d8;
    outline: 0;
    background-color: rgba(18, 23, 27, 0.95);
    border-color: #313646;
    color: white;
}

.searchbar-container {
    position: relative;
    width: 100%;
    float: none !important;
}
.result {
    height: fit-content;
    position: absolute;
    text-align: left;
    z-index: 9999999999999;
    background: #fff;
    margin-top: -1px;
    border: 1px solid #ccc;
    font-size: 12px;
    line-height: 16px;
    width: 240px;
    color: black;
}
.result:hover li{
    color: black;
}
.result ul {
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;
    max-height: 400px;
    overflow-y: auto;
}

.search-clear {
    position: absolute;
    top: 0;
    right: 42px;
    z-index: 2;
    cursor: pointer;
    color: #757575;
    height: 100%;
    width: 38px;
    padding: 0 0 0 10px;
    text-align: left;
}
.search-clear i {
    position: relative;
    display: block;
    font-size: 12px;
    line-height: 24px;
    top: 50%;
    margin-top: -12px;
}

.aws-form-btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background: #ededed;
    border: 1px solid #d8d8d8;
    margin: 0 0 0 -1px;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: background-color 100ms ease-in-out;
    -moz-transition: background-color 100ms ease-in-out;
    -o-transition: background-color 100ms ease-in-out;
    transition: background-color 100ms ease-in-out;
}

.aws-search-btn {
    padding: 0;
    line-height: 10px;
    width: 42px;
}
.aws-search-btn svg {
    display: block;
    height: 100%;
    width: 100%;
}
.searchbar-container form{
    display: flex;
    width: 100%;
    margin: 0;
}
.searchbar-container *, .result {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-hyphens: manual;
    -webkit-hyphens: manual;
    hyphens: manual;
    border-radius: 0 !important;
}
.aws-search-btn_icon {
    display: inline-block;
    fill: currentColor;
    height: 24px;
    line-height: 24px;
    position: relative;
    width: 42px;
    color: #555;
}
.result ul li {
    list-style: none;
    border-bottom: 1px solid #ccc;
    overflow: hidden;
    margin: 0 !important;
    position: relative;
}
.result .result_link {
    display: block;
    cursor: pointer;
    padding: 10px 8px;
    height: 100%;
    overflow: hidden;
    text-decoration: none;
    border: 0;
}
.result .result_link .result_link_top {
    position: absolute;
    z-index: 1;
    white-space: nowrap;
    text-indent: -9999px;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    outline: 0;
    text-decoration: none;
    display: block;
    width: 100%;
}

.result .result_image {
    display: block;
    height: 50px;
    width: 60px;
    float: left;
    padding: 0 10px 0 0;
}

.result .result_image img {
    width: 50px;
    height: auto;
    box-shadow: none;
}

.result .result_content {
    overflow: hidden;
    display: block;
}
.result .result_title {
    display: block;
    font-weight: 400;
    margin-bottom: 3px;
    color: #21759b;
    text-wrap: pretty;
}
.result .result_price {
    display: block;
    padding: 2px 0;
    color: #222;
}

.result .search_more a {
    line-height: 40px!important;
    display: block;
    text-decoration: none;
    border: 0;
    text-align: center;
    color: #21759b;
    font-size: 12px!important;
    font-family: var(--font-family-default)!important;
    letter-spacing: normal!important;
    width: 100%;
    padding: 0!important;
}
.result .no_result {
    line-height: 1.5;
    padding: 10px 6px;
    color: #000;
    cursor: auto;
}