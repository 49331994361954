#section-footer-search {
    padding: 55px;
}

@media (min-width: 1212px) {
    #section-footer-search .container {
        width: 1212px;
    }
}

#section-footer-search .footersearch-mrt {
    position: relative;
}

#section-footer-search input.mrt-search-footer {
    width: 100%;
    padding: 15px;
    background-color: #01060a;
    border: 1px solid #313646;
    outline: none;
    color: white;
}

#section-footer-search .mrt-search-footer-submit {
    border: 1px solid #313646;
    background-color: #313646;
    color: #949baf;
    padding: 15px 20px;
    position: absolute;
    right: 0;
    top: 0;
}