#section-eight {
    padding-top: calc(100vw* 150 / 1900);
    padding-bottom: calc(100vw* 100 / 1900);
}
#section-eight .gallery {
    display: flex;
    column-gap: calc(100vw* 10 / 1900);
}

#section-eight .content-holder .title {
    font-weight: 900;
    font-size: calc(100vw* 60 / 1900);
    line-height: calc(100vw* 36 / 1900);
    text-align: center;
    color: #FFFFFF;
    margin: calc(100vw* 35 / 1900) 0;
}
#section-eight .content-holder .socials {
    text-align: center;
}
#section-eight .content-holder .socials a {
    display: inline-block;
    margin: 0 calc(100vw* 15 / 1900);
}
#section-eight .content-holder .socials a i {
    font-size: calc(100vw* 35 / 1900);
}

@media only screen and (max-width: 765px) {
    #section-eight {
        padding: 50px 0 25px;
    }

    #section-eight .gallery {
        column-gap: 10px;
        margin-bottom: 1.6em;
    }

    #section-eight .content-holder .title {
        font-size: 28px;
        line-height: 32px;
        margin: 15px 0;
    }

    #section-eight .content-holder .socials a {
        margin: 0 10px;
    }

    #section-eight .content-holder .socials a i {
        font-size: 28px;
    }
}