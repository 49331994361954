#features .title-holder h2,
#sizes .title-holder h2,
#options .title-holder h2,
#accessories .title-holder h2,
#match .title-holder h2,
#gallery .title-holder h2,
#brands .title-holder h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: calc(100vw * 240/1900);
    line-height: 1.1;
    letter-spacing: 0;
    color: rgba(255, 255, 255, 0.1);
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}

#options .info-holder p, #accessories .info-holder p {
    font-size: calc(100vw * 16/1900);
}


@media only screen and (max-width: 820px) {
	#banner .mrt-row,
	#features>div.container.fullwidth>div.mrt-row.gradient-bg-rev .mrt-row,
	#features>div.container.fullwidth>div:nth-child(3),
	#options .mrt-row {
		flex-direction: column;
	}
	#banner .mrt-row .mrt-col,
	#features>div.container.fullwidth>div.mrt-row.gradient-bg-rev .mrt-row .mrt-col,
	#features>div.container.fullwidth>div:nth-child(3)>.mrt-col,
	#options .mrt-row .mrt-col {
		width: 100%;
	}
}

@media only screen and (max-width: 498px) {
    #features .title-holder h2,
    #sizes .title-holder h2,
    #options .title-holder h2,
    #match .title-holder h2,
    #gallery .title-holder h2,
    #brands .title-holder h2 {
        font-size: 64px;
    }
}

