#section-1 {
    background-color: #777777;
    z-index: 3;
}

#section-1 .icons {
    text-align: center;
    /* overflow-x: scroll; */
    overflow-y: hidden;
    white-space: nowrap;
    padding: 0 calc(100vw* 15 / 1900) calc(100vw* 15 / 1900);
    -webkit-overflow-scrolling: touch;
}

#section-1 .icons .icon {
    display: inline-flex;
    white-space: break-spaces;
    text-align: center;
    flex-direction: column;
    margin: calc(100vw* 15 / 1900);
    transition: var(--transition);
}

#section-1 .icons .icon .img-holder {
    display: flex;
    width: calc(100vw* 108 / 1900);
    height: calc(100vw* 108 / 1900);
    align-items: center;
    margin: 0 auto;
}

#section-1 .icons .icon:hover {
    transform: scale(1.05);
    transition: var(--transition);
}

#section-1 .icons .icon .name {
    display: block;
    font-weight: 600;
    font-size: calc(100vw * 14/1900);
    line-height: calc(100vw * 17/1900);
    text-align: center;
    color: #262626;
}

@media only screen and (max-width: 765px) {
    #section-1 .icons-holder {
        position: relative;
        padding: 0 15px;
    }    
    #section-1 .icons-holder .arrowleft, #section-1 .icons-holder .arrowright {
        position: absolute;
        font-size: 24px;
        color: #333;
    }
    #section-1 .icons-holder .arrowleft {
        top: 75px;
        left: 0;
    }
    #section-1 .icons-holder .arrowright {
        top: 75px;
        right: 0;
    }
    #section-1 .icons {
        padding: 0 0 10px;
    }
    #section-1 .icons .icon {
        margin: 10px 20px;
    }
    #section-1 .icons .icon .img-holder {
        width: 120px;
        height: 120px;
    }
    #section-1 .icons .icon .name {
        font-size: 12px;
        line-height: 16px;
    }
}