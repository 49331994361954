#cm-banner .four {
	position: relative;
}
#cm-banner .four .bg-holder {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 0;

}
#cm-banner .four .slide-holder {
	background-image: url('/src/components/media/chassis-mount-canopy-product-banner-bg-1-scaled.jpg');
	background-size: cover;
    background-position: 83% 53%;
    background-repeat: no-repeat;
    /* display: flex;
    align-items: center; */
}
#cm-banner .four .bg-holder video {
	width: 100%;
}
#cm-banner .four .slide-holder {
    height: calc(100vw * 597/1900);
}
#cm-banner .four .mrt-row{
    height: calc(100vw * 408/1900);
}
#cm-banner .four .info-holder {
	position: relative;
	z-index: 1;
	padding: 35px;
}
/* #cm-banner .four .info-holder .img-holder {
    margin-bottom: calc(100vw * 35/1900);
}
#cm-banner .four .info-holder .img-holder img {	
    max-width: calc(100vw * 571/1900);
}
#cm-banner .four .info-holder .title {
    padding: 0 !important;
    text-align: left;
    text-transform: none;
    font-size: calc(100vw * 50/1900);
    font-weight: 600;
    line-height: calc(100vw * 64/1900);
	letter-spacing: -0.03em;
    margin-bottom: 0;
} */
#cm-banner .four .info-holder h1 {
	color: #FFF;
	font-size: calc(100vw * 80/1900);
	font-style: normal;
	font-weight: 700;
	line-height: calc(100vw * 88/1900);
	letter-spacing: -1.8px;
	text-transform: capitalize;
	text-align: inherit;
	padding: 0;
    margin-bottom: calc(100vw * 15/1900);
}
/* #cm-banner .four .info-holder h1 img {
    height: calc(100vw * 80/1900);
    width: auto;
    margin-top: -1%;
} */
#cm-banner .four .info-holder h1 img.x-series-logo {
    height: auto;
    width: calc(100vw * 279/1900);
    margin: 0 0 calc(100vw * 24/1900);
}
#cm-banner .four .info-holder h1 img.chassis-mount-stroke {
    height: auto;
    width: calc(100vw * 477/1900);
    margin: 0 0 calc(100vw * 12/1900) 0;
}
#cm-banner .four .info-holder .subtitle {
    font-size: calc(100vw * 48/1900);
    font-weight: 500;
    text-transform: capitalize;
    line-height: 1;
    margin: 0 0 calc(100vw * 25/1900);
}
#cm-banner .four .info-holder .desc {
    font-size: calc(100vw * 30/1900);
    font-weight: 500;
    line-height: calc(100vw * 36/1900);
    margin-bottom: calc(100vw * 35/1900);
}


#cm-banner .four .hvr-ripple-out {
	display: inline-block;
	min-width: calc(100vw* 250 / 1900);
	font-size: calc(100vw * 16/1900);
	letter-spacing: 0;
	border: 1px solid #99cc33;
	background-color: rgba(1,6,10,0.65);
	color: #99cc33;
	padding: calc(100vw* 20 / 1900) calc(100vw* 15 / 1900);
	text-align: center;
	outline: 1px solid;
	outline-offset: -1px;
	text-shadow: none;
	-webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
	-o-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
	transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
	cursor: pointer;
}
#cm-banner .four .hvr-ripple-out.green-btn {
	background-color: #99cc33;
	color: white;
}
#cm-banner .four .hvr-ripple-out.small {
	min-width: auto;
	letter-spacing: 1px;
	padding: 5px 10px;
	font-size: 12px;
}
#cm-banner .four .hvr-ripple-out:before {
	border: none;
}
#cm-banner .four .hvr-ripple-out:hover {
	outline-color: rgba(255, 255, 255, 0);
	outline-offset: 15px;
}

@media only screen and ( max-width: 765px ) {	
    #cm-banner .four .hvr-ripple-out {
		min-width: calc(100vw * 100/390);
		font-size: calc(100vw * 12/390);
		padding: calc(100vw * 5/390) calc(100vw * 10/390);
	}
	#cm-banner .four {
		position: relative;
	}
	#cm-banner .four .bg-holder {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 0;
	}
	#cm-banner .four .bg-holder video {
		width: 100%;
	}
	#cm-banner .four .slide-holder {
		height: calc(100vw * 406/390);
		display: flex;
	}
	#cm-banner .four .mrt-row {
		height: auto;
	}
	#cm-banner .four .info-holder .subtitle {
		font-size: 30px;
		margin: 0 0 8px;
	}
	#cm-banner .four .info-holder {
		padding: calc(100vw * 15/390);
		text-align: center;
	}
	#cm-banner .four .info-holder .img-holder {
		margin-bottom: calc(100vw * 15/390);
		text-align: center;
	}
	#cm-banner .four .info-holder .img-holder img {	
		max-width: calc(100vw * 230/390);
	}
	#cm-banner .four .info-holder h1 img.x-series-logo {
		width: calc(100vw * 279/599);
		margin: 0 0 calc(100vw * 24/599);
	}
	#cm-banner .four .info-holder h1 img.chassis-mount-stroke {
		width: calc(100vw * 477/599);
		margin: 0 0 calc(100vw * 12/599) 0;
	}
	#cm-banner .four .info-holder .title {
		font-size: calc(100vw * 16/390);
		line-height: calc(100vw * 19/390);
		margin-bottom: calc(100vw * 10/390);
		text-align: center;
	}
	#cm-banner .four .info-holder .desc {
		font-size: calc(100vw * 12/390);
		line-height: calc(100vw * 16/390);
		margin-bottom: calc(100vw * 15/390);
	}
	#cm-banner .four .info-holder .button-holder {
		margin-bottom: 0;
	}

}