#section-banner {
    background-image: url('/src/components/media/mrt-adventure-banner-bg.png');
    background-size: auto 100%;
}

#section-banner .row {
    align-items: center;
    height: calc(100vw* 465 / 1900);
}

#section-banner .container {
    padding: unset !important;
    width: unset !important;
}

#section-banner .title {
    font-size: calc(100vw* 100 / 1900);
    font-weight: 800;
    color: var(--color-mrt-green);
    line-height: 1.1em;
    letter-spacing: 0;
    margin-top: 0;
    margin-bottom: calc(100vw* 25 / 1900);
    padding: 0;
}

#section-banner .subtitle {
    font-size: calc(100vw* 32 / 1900);
    font-weight: 700;
    line-height: 1.1em;
    letter-spacing: calc(100vw* 13 / 1900);
    text-align: center;
    position: relative;
    margin-top: 0;
    margin-bottom: calc(100vw* 25 / 1900);
}
#section-banner .subtitle:before {
    content: "";
    display: block;
    height: 1px;
    width: calc(100vw* 630 / 1900);
    background-color: var(--color-mrt-green);
    top: calc(100vw* -15 / 1900);
    left: calc(100vw* 315 / 1900);
    position: absolute;
    z-index: 1;
}


#section-banner .subtitle img {
    height: calc(100vw* 80 / 1900);
    width: auto;
    margin: 0 calc(100vw* 25 / 1900);
}