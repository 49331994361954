#section-one {
    background: linear-gradient(180deg, #121212 0%, black 100%);
}

#section-one img {
    width: 15%;
    height: 100%;
}

#section-one .initiator-holder {
    background-image: url('/src/components/media/initiator-text-stroke (2).png');
    background-size: 75%;
    background-position: center;
    background-repeat: no-repeat;
    padding: calc(100vw* 150 / 1900) 0;
    margin: calc(100vw* 0 / 1900) auto calc(100vw* 70 / 1900);
    max-width: 100%;
}

#section-one h2 {
    margin: 0;
    font-size: calc(100vw* 60 / 1900);
    font-weight: 900;
    text-align: center;
}

#section-one .row.content{
    padding: 0 calc(100vw* 25 / 1900);
    display: flex;
    width: unset;
}


#section-one .switcher {
    display: flex;

}
#section-one .text-holder a{
    font-size: calc(100vw* 16 / 1900);
    font-weight: var(--font-weight-medium);
    line-height: calc(100vw* 20 / 1900);
    margin: 0;
}
#section-one .text-holder{    
    display: flex;
    text-transform: uppercase;
    flex-direction: column;
}

#section-one .image-holder{
    text-align: center;
}


#section-one .product {
    display: flex;
    column-gap: calc(100vw* 40 / 1900);
    padding: calc(100vw* 26 / 1900);
}

#section-one .image-center {
    width: calc(100vw* 900 / 1900);
    height: 100%;
}

#section-one .text-holder span{
    font-size: calc(100vw* 16 / 1900);
    font-weight: var(--font-weight-black);
    line-height: calc(100vw* 20 / 1900);
}


@media only screen and (max-width : 599px) {

    #section-one .initiator-holder {
        background-image: url('/src/components/media/initiator-text-stroke-mobile.png');
        background-size: 84%;

    }

    #section-one .image-holder {       
        display: flex;
        justify-content: center;
    }

    #section-one .image-center {
        width: 300px;
    }

    #section-one h2 {
        font-size: 14px;
    }
    
    #section-one .switcher {
        flex-direction: column-reverse;
        align-items: center;    
    }

    #section-one .product {
        align-items: center;
        column-gap: 40px;
        padding: 26px;
    }

    #section-one .text-holder span,
    #section-one .text-holder a{
        font-size: 14px;
        line-height: 18px;
    }
}