#section-4 {
	padding: calc(100vw * 100/1900) 0;
}
#section-4 .row {
	align-items: center;
}
#section-4 .img-holder {
	padding: 0 calc(100vw * 50/1900);
}
#section-4 .content-holder {
	padding: 0 calc(100vw * 50/1900) 0 calc(100vw * 100/1900);
}
#section-4 .content-holder .title {
	color: #FFF;
	font-family: Montserrat;
	font-size: calc(100vw * 32/1900);;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	text-transform: capitalize;
}
#section-4 .content-holder .desc {
	font-weight: 400;
	font-size: calc(100vw * 16/1900);
	line-height: calc(100vw * 26/1900);
	text-align: justify;
	letter-spacing: 0.03em;
}


@media only screen and (max-width: 765px) {

    #section-4 .row {
		align-items: center;
		flex-direction: column-reverse;
	}
	#section-4 .img-holder,
	#section-4 .content-holder {
		padding: 0;
	}
	#section-4 .content-holder .title {
		text-align: center;
		font-size: calc(100vw * 24/390);
	}
	#section-4 .content-holder .title img {
		width: 80%;
		margin: 15px auto;
	}
	#section-4 .content-holder .desc {
		font-size: 14px;
		line-height: 24px;
	}
}