.section-mrt-blocks .mrt-row {
    display: flex;
    width: 100%;

    
}



#section-two .container {
    padding: calc(100vw * 50/1900) 0;
    width: calc(100vw * 1900/1900);
}

#section-two .mrt-row h1 {
    font-family: var(--font-family-default);
    font-style: normal;
    font-weight: 800;
    font-size: calc(100vw * 80/1900);
    line-height: calc(100vw * 90/1900);
    letter-spacing: -0.02em;
    color: #FFFFFF;
    color: #FFFFFF;
    margin: 0;
    padding: 35px;
    text-align: center;
}
#section-two .mrt-row .cust-text-header {
    color: #0d0d0d;
    background: #FFF;
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-stroke: calc(100vw * 3/1900) transparent;
}

#section-two .mrt-row {
    align-items: end;
}

#section-two .img-holder {
    position: relative;
    
}

#section-two .img-holder video {
    width: calc(100vw * 1100/1900);
    position: relative;
    right: 10%;
    bottom: calc(100vw * 250/1900);
    z-index: -1;
}

#section-two .list-holder .mrt-row {
    align-items: start;
}

#section-two .powdercoated-text {
    font-family: var(--font-family-default);
    font-style: normal;
    font-weight: 900;
    font-size: calc(100vw * 30/1900);
    line-height: calc(100vw * 37/1900);
    text-transform: uppercase;
    color: #99CC33;
    height: calc(100vw * 80/1900) !important;
    display: inline-block;
}

#section-two .list span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: calc(100vw * 18/1900);
    line-height: calc(100vw * 14/1900);
    text-transform: uppercase;
    color: #FFFFFF;
}

#section-two p.from-price span {
    font-weight: 600;
    font-size: calc(100vw * 30/1900);
}

#section-two .list {
    list-style: none;
    padding: 0 calc(100vw * 5/1900) 0 0;
    font-size: calc(100vw * 14/1900);
    margin-top: calc(100vw * 15/1900);
    height: calc(100vw * 350/1900);
}

#section-two .list .item {
    font-family: var(--font-family-default);
    font-style: normal;
    font-weight: 400;
    font-size: calc(100vw * 16/1900);
    line-height: calc(100vw * 26/1900);
    color: #fff;
    margin: calc(100vw * 10/1900) 0;
    max-width: calc(100vw * 430/1900);
}

#section-two p.from-price {
    margin-bottom: 0px;
    font-family: var(--font-family-default);
    font-style: normal;
    font-weight: 900;
    font-size: calc(100vw * 31.5/1900);
    line-height: calc(100vw * 23/1900);
    text-transform: uppercase;
    color: #FFFFFF;
}

#section-two p.current-price {
    font-family: var(--font-family-default);
    font-style: normal;
    font-weight: 900;
    font-size: calc(100vw * 56/1900);
    line-height: calc(100vw * 60/1900);
    text-transform: uppercase;
    color: #9C3;
}

#section-two > .container > div:nth-child(2) .button {
    border: none;
}

#section-two .button-holder .button {
    font-weight: 600;
    font-size: calc(100vw * 17/1900);
    line-height: calc(100vw * 21/1900);
    text-align: center;
    color: black;
    background: #99CC33;
    border: 1px solid #99CC33;
    padding: calc(100vw * 15/1900);
    display: inline-block;
    width: calc(100vw * 230/1900);
}


#section-two .list li.li-head:before {
    content: '';
    width: calc(100vw * 50/1900);
    height: calc(100vw * 3/1900);
    display: block;
    background: #fff;
    margin: calc(100vw * 10/1900) 0;
}
*:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


