#accessories-kes {
    padding: 5% 0;
    background-image: url('/src/components/media/plainbackground.jpg');
    background-size: cover;
}


#accessories-kes .container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 21px;
    padding-right: 21px;
}


#accessories-kes .info-holder {
    padding: 35px;
    background-color: rgba(1, 6, 10, 0.75);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}

#accessories-kes .mrt-subtitle {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 3px;
    line-height: 1em;
    color: #99cc33;
    margin-bottom: 15px;
}

#accessories-kes .mrt-title {
    color: white;
    margin: 0;
    line-height: 1em;
    font-size: 36px;
    font-weight: 900;
    letter-spacing: 3px;
    margin-bottom: 35px;
    text-align: left;
}

#accessories-kes .mrt-desc {
    font-size: 12px;
    font-weight: 300;
    color: white;
    padding-left: 100px;
    line-height: 24px;
    letter-spacing: 1px;
    position: relative;
    margin-bottom: 35px;
}

#accessories-kes .mrt-desc:before {
    content: '';
    width: 75px;
    height: 1px;
    position: absolute;
    top: 8px;
    left: 0;
    background-color: #99cc33;
}



@media (min-width: 1212px) {
    #accessories-kes .container {
        width: 1212px;
    }
}

@media (min-width: 765px) {

    .col-sm-6 {
         width: 50%;
    }
    
}
@media only screen and (max-width: 765px) {

    #accessories-kes .info-holder {
        padding: 35px 15px;
    }
    #accessories-kes .mrt-desc {
        border-style: solid none none none;
        border-top-color: #99cc33;
        border-width: 1px;
        padding-top: 10px;
        font-size: 12px;
        font-weight: 300;
        color: white;
        padding-left: 0px;
        line-height: 24px;
        letter-spacing: 1px;
        position: relative;
        margin-bottom: 35px;
    }
    #accessories-kes .mrt-desc:before {
        display: none;
    }
}