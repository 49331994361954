#section-banner-background {
    z-index: -1;
    position: absolute;
    right: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
}

#section-banner-background video {
    width: 100%;
}

#section-banner-background video {
    object-fit: contain;
    overflow-clip-margin: content-box;
    overflow: clip;
}

#section-banner-background .container {
    padding: calc(100vw * 251/1900) 0;
    width: calc(100vw * 1600/1900);
}

.section-mrt-blocks {
    position: relative;
}

.title {
        margin-top: 0;
        margin-bottom: calc(100vw * 25/1900);
        padding: 0;
}

.section-mrt-blocks .mrt-row {
    display: flex;
    width: 100%;
    align-items: center;
}

.section-mrt-blocks, .section-mrt-blocks .info-holder {
    position: relative;
}

.bg-clear {
    background-color: transparent !important;
    box-shadow: none !important;
}

.info-holder {
    padding: 35px;
    background-color: rgba(1,6,10,0.75);
    box-shadow: 1px 1px 4px rgba(0,0,0,0.5);
}
.title img {
    width: calc(100vw * 680/1900);
    height: auto;
}

.subtitle {
    font-size: calc(100vw * 40/1900);
    margin-top: 0;
    margin-bottom: calc(100vw * 25/1900);
}

#section-banner .btn-primary {
    font-size: calc(100vw * 17/1900);
    font-weight: 600;
    padding: calc(100vw * 15/1900) calc(100vw * 45/1900);
    display: inline-block;
    color: black;
    background-color: #99cc33;
    border: calc(100vw * 1/1900) solid #99cc33;
    transition: var(--transition);
}