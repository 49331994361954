#section-next-steps .container {
    padding-left: 0;
    padding-right: 0;
}
#section-next-steps .steps-contact {
    margin-top: 35px;
    margin-bottom: 35px;
}

#section-next-steps .container:before,
#section-next-steps .container:after,
#section-next-steps .container-fluid:before,
#section-next-steps .container-fluid:after,
#section-next-steps .row:before,
#section-next-steps .row:after {
    display: table;
    content: " ";
}
#section-next-steps .steps-contact .info-holder {
    padding: 55px;
}

#section-next-steps .info-holder {
    padding: 35px;
    background-color: rgba(1, 6, 10, 0.75);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}

#section-next-steps .mrt-title {
    color: white;
    margin: 0;
    line-height: 1em;
    font-size: 36px;
    font-weight: 900;
    letter-spacing: 3px;
    margin-bottom: 35px;
    text-align: left;
}
#section-next-steps .steps-contact .info-holder p {
    border-left: 0;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}
#section-next-steps .hidden{
    display: none;
}

#section-next-steps .steps-contact .step-desc {
    font-size: calc(100vw * 16/1900);
    line-height: calc(100vw * 24/1900);
    font-weight: 300;
    color: white;
    letter-spacing: 1px;
    margin-bottom: 35px;
    padding-right: 6%;
}

#section-next-steps .steps-contact .step-desc a {
    font-weight: 800;
}

#section-next-steps .container:after,
.container-fluid:after,
.row:after {
    clear: both;
}
#section-locations .col-33,
#section-cta .col-3 {
    width: 33.33%;
}
#section-locations .content-holder {
    border: 1px solid #9C3;
    margin: calc(100vw* 12 / 1900);
    text-align: center;
}

#section-cta {
    margin: calc(100vw* 38 / 1900) 0;
}
#section-locations .col-100,
#section-cta .col-100 {
    width: 100%;
}
#section-locations .row,
#section-cta .row {
    display: flex;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
}
#section-locations .content-holder h3 {
    text-align: center;
    text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.90);
    font-family: Montserrat;
    font-size: calc(100vw* 36 / 1900);
    font-style: normal;
    font-weight: 900;
    line-height: calc(100vw* 42 / 1900);
    margin: calc(100vw* 24 / 1900);
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vw* 100 / 1900);
}
#section-locations .content-holder a {
    border-radius: calc(100vw* 4 / 1900);
    background: #9C3;
    padding: calc(100vw* 8 / 1900);
    margin: calc(100vw* 24 / 1900);
}
#section-locations .content-holder a span {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: calc(100vw* 22 / 1900);
    font-style: normal;
    font-weight: 600;
    line-height: calc(100vw* 22 / 1900);
}
#section-locations .content-holder p {
    margin: calc(100vw* 14 / 1900);
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: calc(100vw* 14 / 1900);
    font-style: normal;
    font-weight: 400;
    line-height: calc(100vw* 22 / 1900);
}
#section-locations .content-holder span a {
    background: unset;
    padding: unset;
    margin: unset;
    border: unset;
    border-radius: unset;
}
#section-locations .content-holder span img {
    width: calc(100vw* 24 / 1900);
}


/* for the cta section */
#section-cta .col-100 .content-holder p {
    color: #FFF;
    text-align: justify;
    font-family: Montserrat;
    font-size: calc(100vw* 16 / 1900);
    font-style: normal;
    font-weight: 400;
    line-height: calc(100vw* 26 / 1900);
    margin-top: 0;
    margin-bottom: 35px !important;
}
#section-locations .col-50,
#section-cta .col-50 {
    width: 50%;
}
#section-cta .col-50 .content-holder a {
    color: #FFF;
    font-family: Montserrat;
    font-size: calc(100vw* 42 / 1900);
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
#section-cta .col-50 .content-holder a img {
    width: calc(100vw* 36 / 1900);
    margin: calc(100vw* 12 / 1900);
}




@media (min-width: 1212px) {
    #section-next-steps .container {
        width: 1212px;
    }
}

@media (min-width: 768px) {
    .col-sm-12 {
        width: 100%;
    }
}

@media only screen and (max-width: 765px) {
    #section-next-steps .steps-contact .step-desc {
        font-size: 14px;
        line-height: 24px;
    }
}
@media only screen and (max-width: 690px) {
    #section-locations .content-holder p {
        margin: calc(100vw* 14 / 699);
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: calc(100vw* 14 / 699);
        font-style: normal;
        font-weight: 400;
        line-height: calc(100vw* 22 / 699);
    }

    #section-locations .row,
    #section-cta .row {
        flex-direction: column;
    }

    #section-locations .col,
    #section-cta .col {
        width: 100% !important;
    }

    #section-cta .col-50 .content-holder {
        text-align: center !important;
    }

    #section-cta .col-100 .content-holder p {
        color: #FFF;
        text-align: justify;
        font-family: Montserrat;
        font-size: calc(100vw* 16 / 699);
        font-style: normal;
        font-weight: 400;
        line-height: calc(100vw* 26 / 699);
    }

    #section-cta .col-50 .content-holder a {
        color: #FFF;
        font-family: Montserrat;
        font-size: calc(100vw* 42 / 699);
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }

    #section-cta .col-50 .content-holder a img {
        width: calc(100vw* 36 / 699);
        margin: calc(100vw* 12 / 699);
    }
    #section-locations .container, #section-cta .container {
        padding-left: 21px;
        padding-right: 21px;   
    }
}
