@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
        url('../fonts/source-sans-pro-v13-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/source-sans-pro-v13-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

#site-header-wrapper {
    height: auto !important;
    position: sticky;
    top: 0;
    z-index: 999;
}

.site-logo .logo {
    display: block;
    height: 49px;
    transition: height 0.1s ease-out;
}

.site-logo .logo.isScrolled {
    height: 37.24px;
    transition: height 0.3s;
}

.header_logo {
    width: 125px;
    transition: width 0.2s ease-out;
}

.header_logo.isScrolled {
    width: 95px;
    transition: width 0.3s;
}

.back-to-top {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    bottom: calc(100vw * 177/1870);
    right: calc(100vw * 32/1870);
    z-index: 9999;
    border: none;
    text-align: center;
    font-size: calc(100vw*22/1870);
    cursor: pointer;
    transition: all 0.3s;
    vertical-align: top;
    width: calc(100vw * 55/1870);
    height: calc(100vw * 55/1870);
    line-height: calc(100vw * 55/1870);
    background-color: #06060f;
    color: #99a9b5;
}



.m-box {
    display: table-cell;
    width: 98%;
}

.back-to-top.isScrolled {
    visibility: visible;
    opacity: .5;
    transition: all 0.3s;
}

.mrt-top-banner {
    padding: 10px !important;
    text-align: center;
    background-color: var(--color-mrt-green);
}

.slideshow-container {
    position: relative;
    margin: auto;
}

#site-header .mrt-top-banner p {
    margin: 0;
    color: black;
    font-weight: 400;
    line-height: 1.1em;
}

.mrt-top-banner a {
    color: black;
    font-weight: bold;
}

.mrt-top-banner p {
    line-height: 1.1em;
    font-size: 12px;
    letter-spacing: 1px;
}

.top-area-background {
    position: relative;
}

header .container {
    width: 80%;
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 0;
    padding-right: 0;
}

.top-area-alignment-right .top-area-items {
    text-align: right;
}



.top-area-block {
    position: relative;
    vertical-align: middle;
}

#top-area-menu,
.top-area-button {
    display: inline-block;
    vertical-align: top;
}

ol,
ul {
    box-sizing: border-box;
}

ul.styled {
    padding: 0;
    list-style: none;
}

.top-area-menu .nav-menu>li {
    display: inline-block;
    padding: 0 15px;
    line-height: 30px;
}


.top-area-menu .nav-menu>li a {
    color: white;
    display: block;
}

.header-background {
    position: relative;
}

#site-header .header-main {
    position: relative;
    z-index: 11;
}


.mobile-minicart-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    background: #000;
    opacity: 0;
    visibility: hidden;
    transition: all 1s;
}


#site-header .site-title {
    display: table-cell;
    vertical-align: middle;
    padding: 10px;
}

.top-area-background:before,
.header-background:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;

}


#site-header .primary-navigation {
    display: table-cell;
    width: 99%;
    vertical-align: middle;
    text-align: right;
    position: relative;
    z-index: 10;
}

.mobile-menu-slide-wrapper .mobile-menu-slide-close {
    display: none;
}

button {
    outline: none;
    cursor: pointer;
}

input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

#primary-navigation .menu-toggle {
    display: none;
}

#primary-menu.no-responsive {
    text-align: left;
    white-space: nowrap;
    display: inline-block;
    font-size: 0;
    margin-left: 20px;
}

#primary-menu.no-responsive>li {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding: 15px 0 !important;

}


#primary-menu.no-responsive>li>a {
    color: white;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 2px;
    padding: 0 10px;
    text-transform: uppercase;
    display: block;
    line-height: 25px;
    height: 25px;
}


ul.styled,
ol.styled {
    margin: 0;
    padding: 0;
    list-style: none;
}


#site-header .primary-navigation,
#site-header #perspective-menu-buttons {
    display: table-cell;
    width: 99%;
    vertical-align: middle;
    text-align: right;
    position: relative;
    z-index: 10;
}

#site-header .primary-navigation {
    text-align: right;
}

#primary-menu.no-responsive>li>ul {
    top: 100%;
    left: 0;
}

.menu-item:not(.adv)>ul {
    border: none !important;
    position: absolute;
    visibility: hidden;
    white-space: normal;
    color: var(--color-mrt-green);
    width: calc(100vw * 308/1870);
    -webkit-transition: transform .1s linear .5s, opacity .1s linear .5s, visibility .1s linear .5s;
    -moz-transition: transform .1s linear .5s, opacity .1s linear .5s, visibility .1s linear .5s;
    -webkit-transition: transform .1s linear .5s, opacity .1s linear .5s, visibility .1s linear .5s;
    transition: transform .1s linear .5s, opacity .1s linear .5s, visibility .1s linear .5s;
    opacity: 0;
}

#primary-menu.no-responsive .sub-menu {
    border: none;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}

#primary-menu.no-responsive>li:not(.megamenu-template-enable) li>a {
    line-height: 20px;
    font-size: calc(100vw * 18/1870);
}


#top-area {
    border: none !important;
    background: black;
    padding: 5px 0;
}

#top-area-navigation li:last-child {

    padding-right: 0;
}

#top-area-navigation li:last-child a {
    font-size: 16px !important;
    font-weight: bold;
    color: var(--color-mrt-green) !important;
}

.top-area-menu .nav-menu>li a {
    color: white;
    font-size: 10px;
}

.top-area-menu .nav-menu>li a:hover,
.top-area-menu .nav-menu>li a:active {
    color: var(--color-mrt-green);
}

.top-area-menu .nav-menu>li a:before {
    display: none;
}

#site-header,
#site-header-wrapper,
.header-layout-fullwidth_hamburger #primary-menu {
    background-color: #01060a;
}

#site-header.fixed.shrink {
    background-color: rgba(1, 6, 10, 0.95) !important;
}

#primary-menu.no-responsive>li>a {
    color: white;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 2px;
    padding: 0 10px;
    text-align: right;
}

#primary-menu.no-responsive>li.menu-item-current>a,
#primary-menu.no-responsive>li.menu-item-active>a,
#primary-menu.no-responsive>li:hover>a {
    color: var(--color-mrt-green);
}

#primary-menu.no-responsive .sub-menu {
    border: none;
    box-shadow: calc(100vw * 1/1870) calc(100vw * 1/1870) calc(100vw * 4/1870) rgba(0, 0, 0, 0.5);
}

#primary-menu.no-responsive .sub-menu>li>a {
    background-color: rgba(1, 6, 10, 0.95);
    color: white;
    border: none;
    font-family: montserrat;
    font-size: calc(100vw * 13/1870);
    letter-spacing: 1px;
}

#primary-menu.no-responsive .sub-menu>li>a:hover,
#primary-menu.no-responsive .sub-menu>li.menu-item-active>a {
    background-color: rgba(18, 23, 27, 0.95);
    color: white;
    border-color: var(--color-mrt-green) !important;
}

#site-header .site-title {
    padding: 10px;
}


.menu-item-search:not(.adv) {
    display: none !important;
}

.menu-item-search .minisearch {
    position: absolute;
    top: 100%;
    left: 0;
    padding: calc(100vw * 22/1870);
    width: calc(100vw * 308/1870);
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(calc(100vw * 48/1870));
    transform: translateY(calc(100vw * 48/1870));
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s;
}

.minisearch.invert {
    left: auto;
    right: calc(100vw * -20/1870);
}

.menu-item-search .minisearch.active {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: opacity .5s, -webkit-transform .5s;
    transition: opacity .5s, transform .5s;
}


.menu-item-search.adv .minisearch {
    padding-top: 0;
}


.menu-item-search.adv .minisearch .searchbar {
    background-color: rgba(18, 23, 27, 0.95);
    border-radius: 0;
    border-color: #313646;
    color: white;
}

.menu-item-search a {
    width: calc(100vw * 30/1870);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.minicart {
    background-color: rgba(18, 23, 27, 0.95) !important;
    border-radius: 0;
    border-color: #313646 !important;
    font-family: "Montserrat", sans-serif;
}

.menu-item-cart>a .minicart-item-count {
    background-color: var(--color-mrt-green);
}

.menu-item-cart>.minicart .total,
.menu-item-cart>.minicart .buttons,
.hamburger-minicart>.minicart .total,
.hamburger-minicart>.minicart .buttons,
.mobile-cart-position-top #site-header .mobile-cart .total,
.mobile-cart-position-top #site-header .mobile-cart .buttons {
    background-color: rgba(18, 23, 27, 0.95) !important;
}

#primary-menu.no-responsive>li>ul.invert {
    left: auto;
    right: 0;
}

.header-main:not(.header-layout-overlay) #primary-menu.no-responsive:hover li:not(.megamenu-template-enable):hover>ul,
.header-main:not(.header-layout-overlay) #primary-menu.no-responsive:hover li.megamenu-template-enable:hover .megamenu-template,
#thegem-perspective .perspective-navigation #primary-menu.no-responsive:hover li:not(.megamenu-template-enable):hover>ul,
#thegem-perspective .perspective-navigation #primary-menu.no-responsive:hover li.megamenu-template-enable:hover .megamenu-template {
    visibility: visible;
    transition: transform .3s linear, opacity .3s linear, visibility .3s linear;
    -moz-transition: transform .3s linear, opacity .3s linear, visibility .3s linear;
    -webkit-transition: transform .3s linear, opacity .3s linear, visibility .3s linear;
    opacity: 1;
    transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
}

#primary-menu.no-responsive:hover>li:not(.megamenu-template-enable,
    .adv) ul,
#primary-menu.no-responsive:hover>li .megamenu-template {
    visibility: hidden;
    transition: transform .1s linear, opacity .1s linear, visibility .1s linear;
    -moz-transition: transform .1s linear, opacity .1s linear, visibility .1s linear;
    -webkit-transition: transform .1s linear, opacity .1s linear, visibility .1s linear;
    opacity: 0;
}

#primary-menu.no-responsive>li:not(.megamenu-template-enable)>ul,
#primary-menu.no-responsive>li .megamenu-template {
    transform: translateY(calc(100vw * 22/1870));
    -o-transform: translateY(calc(100vw * 22/1870));
    -ms-transform: translateY(calc(100vw * 22/1870));
    -moz-transform: translateY(calc(100vw * 22/1870));
    -webkit-transform: translateY(calc(100vw * 22/1870));
}

.submenu-item,
#primary-menu.no-responsive>li.megamenu-enable>ul li>a,
#primary-menu.no-responsive>li:not(.megamenu-template-enable) li>a,
#primary-menu.no-responsive>li:not(.megamenu-template-enable) .minicart-product-title,
.portfolio-filters-resp ul li a,
.widget_nav_menu ul.menu,
.widget_submenu>div>ul,
.widget_categories>ul,
.widget_product_categories>ul,
.widget_pages>ul {
    line-height: calc(100vw * 22/1870);
    letter-spacing: 1px;
}

.submenu-item,
#primary-menu.no-responsive>li.megamenu-enable>ul li>a,
#primary-menu.no-responsive>li:not(.megamenu-template-enable) li>a,
#primary-menu.no-responsive>li:not(.megamenu-template-enable) .minicart-product-title,
.portfolio-filters-resp ul li a,
.widget_nav_menu ul.menu,
.widget_submenu>div>ul,
.widget_categories>ul,
.widget_product_categories>ul,
.widget_pages>ul {
    font-size: calc(100vw * 17/1870);
}

.submenu-item,
#primary-menu.no-responsive>li.megamenu-enable>ul li>a,
#primary-menu.no-responsive>li:not(.megamenu-template-enable) li>a,
#primary-menu.no-responsive>li:not(.megamenu-template-enable) .minicart-product-title,
.portfolio-filters-resp ul li a,
.widget_nav_menu ul.menu,
.widget_submenu>div>ul,
.widget_categories>ul,
.widget_product_categories>ul,
.widget_pages>ul {
    font-family: "Source", sans-serif;
}

#primary-menu.no-responsive>li>ul a {
    padding: calc(100vw * 17/1870) calc(100vw * 33/1870);
    position: relative;
    display: block;
    text-decoration: none;
}

#primary-menu.no-responsive>li:not(.megamenu-template-enable) li.menu-item-parent>a i {
    position: absolute;
    width: calc(100vw * 10/1870);
    height: calc(100vw * 18/1870);
    right: calc(100vw * 22/1870);
    top: 50%;
    margin-top: calc(100vw * -9/1870);
    font-weight: 400;
    font-size: calc(100vw * 18/1870);
    line-height: calc(100vw * 18/1870);
}

.header-main:not(.header-layout-overlay) #primary-menu.no-responsive:hover li:not(.megamenu-template-enable):hover>ul,
.header-main:not(.header-layout-overlay) #primary-menu.no-responsive:hover li.megamenu-template-enable:hover .megamenu-template,
#thegem-perspective .perspective-navigation #primary-menu.no-responsive:hover li:not(.megamenu-template-enable):hover>ul,
#thegem-perspective .perspective-navigation #primary-menu.no-responsive:hover li.megamenu-template-enable:hover .megamenu-template {
    visibility: visible;
    transition: transform .3s linear, opacity .3s linear, visibility .3s linear;
    -moz-transition: transform .3s linear, opacity .3s linear, visibility .3s linear;
    -webkit-transition: transform .3s linear, opacity .3s linear, visibility .3s linear;
    opacity: 1;
    transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
}

#primary-menu.no-responsive>li>ul ul {
    top: 0;
    left: 100%;
    transform: translateX(calc(100vw * 22/1870));
    -o-transform: translateX(calc(100vw * 22/1870));
    -ms-transform: translateX(calc(100vw * 22/1870));
    -moz-transform: translateX(calc(100vw * 22/1870));
    -webkit-transform: translateX(calc(100vw * 22/1870));
    z-index: 1;
}

#primary-menu.no-responsive .sub-menu>li>a:hover,
#primary-menu.no-responsive .sub-menu>li.menu-item-active>a {
    background-color: rgba(18, 23, 27, 0.95);
    color: white;
    border-left: calc(100vw * 3/1870) solid var(--color-mrt-green);
}

.menu-item-cart {
    display: table-cell;
    position: relative;
    vertical-align: middle;
    padding-left: calc(100vw * 17/1870);
    width: calc(100vw * 40/1870);
    height: calc(100vw * 50/1700);
    text-align: center;
}

.menu-item-cart>a i,
.hamburger-minicart>a i {
    font-weight: 400;
    font-style: normal;
    font-size: calc(100vw * 20/1870);
    vertical-align: top;
    line-height: inherit;
    display: inline-block;
    text-align: right;
    width: 100%;
}

.menu-item-cart>a {
    padding: 0 !important;
    color: white;
}

.menu-item-cart:hover>a {
    color: var(--color-mrt-green);
}

.hamburger-minicart>a .minicart-item-count,
.menu-item-cart>a.circle-count .minicart-item-count {
    position: absolute;
    width: calc(100vw * 22/1870);
    height: calc(100vw * 22/1870);
    top: calc(100vw * 17/1870);
    right: 0;
    left: auto;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(100vw * 12/1870);
    color: #000;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

.menu-item-cart>a.circle-count .minicart-item-count {
    width: calc(100vw * 20/1870);
    height: calc(100vw * 20/1870);
    line-height: calc(100vw * 20/1870);
    top: 50%;
    margin-top: calc(100vw * -12/1870);
}


.menu-item-search .searchicon {
    padding: 0 !important;
}



@media (min-width: 768px) {
    .top-area .top-area-items .top-area-block {
        height: 30px;
        line-height: 30px;
    }
}


@media (max-width: 1212px) {
    #site-header #primary-navigation>div {
        background-color: rgba(1, 6, 10, 0.95);
    }

    #site-header .site-title,
    #site-header .site-logo {
        width: 100%;
    }

    #site-header .site-logo {
        display: flex;
        justify-content: center;
    }

    .site-logo .logo {
        display: block;
        height: unset;
        transition: height 0.1s ease-out;
    }

    .menu-item-cart {
        vertical-align: middle;
        width: 36px;
        height: unset;
    }


    .menu-item-cart>a i {
        font-weight: 400;
        font-style: normal;
        font-size: 20px;
        vertical-align: top;
        line-height: inherit;
        display: inline-block;
        text-align: right;
        width: 100%;
        color: #3c3950;
    }

    .mobile-menu-layout-slide-horizontal #primary-navigation .mobile-menu-slide-wrapper,
    .mobile-menu-layout-slide-vertical #primary-navigation .mobile-menu-slide-wrapper {
        position: fixed;
        overflow-y: auto;
        padding-top: 75px;
        z-index: 1003;
        -webkit-overflow-scrolling: touch;
        opacity: 0;
    }

    .mobile-menu-layout-slide-vertical #primary-navigation .mobile-menu-slide-wrapper.top {
        left: 0;
        right: 0;
        top: -100vh;
        height: 100vh;
        overflow: hidden;
    }

    .mobile-menu-layout-slide-vertical #primary-navigation .mobile-menu-slide-wrapper.top.opened {
        top: 0;
        opacity: 1;
        visibility: visible;
    }

    #primary-menu,
    #primary-menu+ul {
        text-align: left;
        padding-bottom: 30px;
        min-height: 100%;
    }

    #primary-navigation #primary-menu>li a,
    #primary-navigation #primary-menu>li .menu-item-parent-toggle,
    #primary-navigation ul.dl-submenu.level1>li>a,
    .primary-navigation .minisearch .sf-input,
    .mobile-menu-layout-overlay #primary-navigation #primary-menu>li.menu-item-search>.minisearch input[type=text],
    .mobile-menu-layout-overlay #primary-navigation #primary-menu>li.menu-item-search>.minisearch .sf-submit-icon:before,
    .mobile-cart-position-top .menu-overlay.mobile.active+#site-header .mobile-cart>a:before {
        color: #5f727f;
    }

    #primary-navigation .mobile-menu-slide-wrapper .mobile-menu-slide-close {
        display: block;
        border: none;
        width: 36px;
        height: 30px;
        text-indent: 100%;
        font-size: 0;
        line-height: 0;
        overflow: hidden;
        position: absolute;
        right: 19px;
        top: 20px;
        cursor: pointer;
        outline: none;
        background: 0 0;
        padding: 0;
    }


    .mobile-menu-slide-close::before,
    .mobile-menu-slide-close::after {
        background-color: rgb(95, 114, 127);
        width: 30px;
        top: 15px;
        display: block;
        content: '';
        position: absolute;
        height: 4px;
        left: 0;
    }

    #primary-navigation .mobile-menu-slide-close:before {
        transform: rotate(45deg);
    }

    #primary-navigation .mobile-menu-slide-close:after {
        transform: rotate(-45deg);
    }

    .mobile-menu-layout-slide-horizontal #primary-navigation .mobile-menu-slide-wrapper,
    .mobile-menu-layout-slide-vertical #primary-navigation .mobile-menu-slide-wrapper,
    .mobile-menu-layout-slide-horizontal #primary-navigation #primary-menu,
    .mobile-menu-layout-slide-vertical #primary-navigation #primary-menu,
    #primary-navigation #primary-menu>li>a,
    #primary-navigation ul.dl-submenu.level1>li>a,
    #primary-navigation #primary-menu li.menu-item-search,
    #primary-navigation #primary-menu li.menu-item-search .minisearch {
        background-color: #f4f6f7;
    }

    .mobile-menu-layout-slide-horizontal #primary-navigation #primary-menu li a,
    .mobile-menu-layout-slide-vertical #primary-navigation #primary-menu li a {
        padding: 15px;
        display: block;
        position: relative;
        transition: all 0.2s;
    }

    #page #site-header .primary-navigation,
    #page #site-header #perspective-menu-buttons {
        display: table-cell;
        text-align: right;
    }

    #primary-navigation .mobile-menu-slide-wrapper {
        position: fixed;
        overflow-y: auto;
        padding-top: 75px;
        z-index: 1003;
        -webkit-overflow-scrolling: touch;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    ul.styled,
    ol.styled,
    ul.product_list_widget {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .header-main {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .header_logo {
        width: 95px;
        transition: width 0.2s ease-out;
    }

    .m-box {
        width: 100%;
        display: flex;
        justify-content: end;
    }


    #site-header .primary-navigation {
        position: static;
        padding: 15px 0;
        vertical-align: middle;
        width: 36px;
    }

    .mobile-cart-position-top #site-header .primary-navigation,
    .mobile-cart-position-top #site-header #perspective-menu-buttons {
        width: auto;
    }

    #primary-navigation .menu-toggle,
    #perspective-menu-buttons .menu-toggle {
        display: inline-block;
        border: none;
        width: 36px;
        height: 30px;
        text-indent: 100%;
        font-size: 0;
        line-height: 0;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        outline: none;
        background: 0 0;
        padding: 0;
    }

    #primary-navigation .menu-toggle .menu-line-1,
    #primary-navigation .menu-toggle .menu-line-2,
    #primary-navigation .menu-toggle .menu-line-3,
    #perspective-menu-buttons .menu-toggle .menu-line-1,
    #perspective-menu-buttons .menu-toggle .menu-line-2,
    #perspective-menu-buttons .menu-toggle .menu-line-3,
    #perspective-menu-buttons .perspective-toggle .menu-line-1,
    #perspective-menu-buttons .perspective-toggle .menu-line-2,
    #perspective-menu-buttons .perspective-toggle .menu-line-3 {
        position: absolute;
        background: #3c3950;
        -webkit-transition: all .5s, top .3s, height .3s;
        transition: all .5s, top .3s, height .3s;
    }

    #primary-navigation .menu-toggle .menu-line-1,
    #perspective-menu-buttons .menu-toggle .menu-line-1 {
        top: 9px;
    }

    #primary-navigation .menu-toggle .menu-line-2,
    #perspective-menu-buttons .menu-toggle .menu-line-2 {
        top: 14px;
    }

    #primary-navigation .menu-toggle .menu-line-3,
    #perspective-menu-buttons .menu-toggle .menu-line-3 {
        top: 19px;
    }

    #primary-navigation .menu-toggle .menu-line-1,
    #primary-navigation .menu-toggle .menu-line-2,
    #primary-navigation .menu-toggle .menu-line-3,
    #perspective-menu-buttons .menu-toggle .menu-line-1,
    #perspective-menu-buttons .menu-toggle .menu-line-2,
    #perspective-menu-buttons .menu-toggle .menu-line-3 {
        height: 2px;
        width: 18px;
        left: 9px;
    }


    .mobile-menu-layout-slide-horizontal #primary-navigation #primary-menu li.menu-item-search .minisearch,
    .mobile-menu-layout-slide-vertical #primary-navigation #primary-menu li.menu-item-search .minisearch {
        position: absolute;
        top: 0;
        left: 0;
        right: 55px;
        background: 0 0;
        width: auto;
        padding-top: 15px;
    }

    .primary-navigation .minisearch {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        border: none;
        visibility: visible;
        padding: 20px 30px;
    }


    .searchbar-container form {
        position: relative;
        width: 100%;
        float: none !important;
        display: -webkit-box !important;
        display: -moz-box !important;
        display: -ms-flexbox !important;
        display: -webkit-flex !important;
        display: flex !important;
        align-items: stretch;
        height: 44px;
    }

    .searchbar-container form .input-wrapper {
        flex-grow: 1;
        position: relative;
        vertical-align: top;
        padding: 0;
        width: 100%;
        overflow: hidden;
    }

    .searchbar-container .searchbar {
        font-size: 16px;
        width: 100%;
        max-width: 100%;
        color: #313131;
        padding: 6px;
        line-height: 30px;
        display: block;
        position: relative;
        z-index: 2;
        -webkit-appearance: none;
        appearance: none;
        height: 100%;
        margin: 0 !important;
        border: 1px solid #d8d8d8;
        outline: 0;
    }

    .search-clear {
        right: calc(100vw * 5/412) !important;
    }

    .result {
        position: unset !important;
        width: 100% !important;
    }

    .result_link_top {
        position: absolute !important;
    }

    .menu-item-search a {
        display: unset;
    }

    .menu-item-has-children {
        position: relative;
    }

    .mobile-menu-layout-slide-horizontal .primary-navigation li.menu-item-has-children>.menu-item-parent-toggle,
    .mobile-menu-layout-slide-vertical .primary-navigation li.menu-item-has-children>.menu-item-parent-toggle {
        display: block;
        position: absolute;
        right: 15px;
        top: 0;
        width: 40px;
        height: 49px;
        cursor: pointer;
        text-align: center;
        line-height: 49px;
        transition: all 0.1s;
    }

    .menu-item:not(.adv)>ul{
        position: unset;
        visibility: visible;
        white-space: normal;
        color: unset;
        width: unset;
        transition: unset;
        opacity: 1;
    }

    .mobile-menu-layout-slide-horizontal #primary-navigation #primary-menu li>ul,
    .mobile-menu-layout-slide-vertical #primary-navigation #primary-menu li>ul {
        display: none;
        padding: 0 !important;
        background-image: none !important;
        max-height: 0;
        transition: max-height 0.2s!important;
    }

    .menu-item-open>a{
        background-color: #fff!important;
        color: #3c3950!important;
        transition: all 0.2s;
    }

    .menu-item-parent-toggle.rotate{
        color: #3c3950!important;
        transform: rotate(90deg);
        transition: all 0.1s;
    }

    .sub-menu li a{
        padding-left: 20px!important;
    }

    .sub-menu li .sub-menu li a{
        padding-left: 25px!important;
    }
    .sub-menu-open {
        display: block!important;
        margin-top: 0px!important;
        margin-bottom: 0px!important;
        max-height: unset!important;
        transition: max-height 0.2s!important;
    }

    .mobile-menu-layout-slide-vertical #primary-navigation #primary-menu li{
        border-top: 1px solid #dfe5e8;
    }

    #primary-navigation #primary-menu > li > ul > li > a{
        color: #5f727f;
    }

    .menu-item-type-custom>a>i{
        display: none;
    }

    .back-to-top{
        font-size: 20px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        right: 29px;
        bottom: 161px;
    }

}