#section-default-content {
    padding: 5% 0;
}

#general_product_page .vc_column-inner {
    padding-left: 21px;
    padding-right: 21px;
}


#general_product_page .products-grid {
    min-height: 500px;
}

#genera_product_page .portfolio-top-panel:not(.sticky-fixed) {
    position: relative;
}

#genera_product_page .portfolio-top-panel {
    margin: 0 0 55px;
    overflow: visible;
}
#genera_product_page .portfolio-top-panel {
    display: table;
    width: 100%;
}

#genera_product_page .portfolio-top-panel-row {
    display: flex;
    align-items: flex-start;
}

#genera_product_page .portfolio-top-panel-left {
    flex: auto;
}

#genera_product_page .portfolio-top-panel-left, .portfolio-top-panel-right {
    display: table-cell;
    vertical-align: top;
}


#general_product_page .portfolio-filters {
    position: absolute;
    left: 0;
    width: 24%;
}

.portfolio .portfolio-top-panel .portfolio-filters, .portfolio .portfolio-top-panel .portfolio-filters-resp {
    position: relative;
    z-index: 5;
}

#general_product_page .portfolio-filters a {
    display: block;
    border-radius: 0;
    margin-bottom: 0;
    padding: 15px 20px;
    background: rgb(2, 6, 9);
    line-height: 1.2em;
    text-align: left;
    font-size: 12px;
    margin-right: 0; 

    
}

#general_product_page .portfolio-filters a.active, #general_product_page .portfolio-filters a:hover {
    background: rgb(20, 23, 27);
    border-left: 3px solid #99cc33;
    transition: 0.2s ease-in-out all;
}

#general_product_page span {
    font-family: "Montserrat", sans-serif;
    color: #99cc33;

}
#general_product_page .title-h6 {
    text-transform: uppercase;
    letter-spacing: .05em;
}

#general_product_page .products .row{ 
    margin: -21px;
    
}

#general_product_page .products .portfolio-set {
    height: auto !important;
    position: relative;
    padding-top: 21px;
}   

#general_product_page .products .portfolio-set .portfolio-item {
    padding: 21px;
    display: inline-block;
    position: unset !important;
    width: 33.33%;
}
#general_product_page .products .portfolio-set .portfolio-item .wrap {
    border: 1px solid #838383;
    width: 100%;
}

.portfolio-item .image {
    display: inline-block;
    position: relative;
    max-width: 100%;
    padding: 0;
    vertical-align: top;
    overflow: hidden;
    flex: none;
}

#general_product_page .portfolio-item .image .image-inner {
    max-width: 252px;
    min-height: 252px;
}

.portfolio-item .image .image-inner {
    overflow: hidden;
}
.portfolio-item .image .image-inner {
    position: relative;
    z-index: 2;
}
.product > a > .image-inner {
    margin: 0 auto;
}

.portfolio-item .image .image-inner img {
    position: relative;
    left: 0;
    min-width: initial;
    height: 252px !important;
    object-fit: cover;
}

#general_product_page .portfolio-item .caption {
    min-height: 167px;
    background-color: transparent;
    padding: 15px;
    position: relative;
    z-index: 1;
}


#general_product_page .portfolio-item .product-info {
    padding: 0 0 0 0;
    text-align: center;
}


#general_product_page .portfolio-item .product-info .title {
    letter-spacing: 0;
    line-height: 25px;
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;

}

#general_product_page .mrt-product-category {
    font-weight: 16px !important;
    font-size: 400 !important;
    letter-spacing: 1px;
    margin-bottom: 0 !important;
    color: #99cc33;
    line-height: 24px;
    position: relative;
    margin: 0 0 1.1em;
}

#general_product_page .products .portfolio-item.product .caption .title a {
    font-size: 14px;
    line-height: 1.5em ;
    color: white ;
}

#general_product_page .products .product-price span{
    font-size: 18px;
    font-weight: 900;
    font-family: "Montserrat", sans-serif;
    color: white;

}

#general_product_page .products .product-price {
    text-align: center;

}

.mycostumproduct {
    min-height: 110px;
}
.mycostumproduct a {
    display: inline-block;
    margin-top: 35px !important;
}

.mycostumproduct p {
    color: #838383;
    font-size: 16px ;
    font-weight: 400 ;
    line-height: 24px;
    letter-spacing: 1px;
    position: relative;
    margin: 0 0 1.1em;
    text-align: center;
}


#general_product_page .mycostumproduct .hvr-ripple-out {
    min-width: 200px;
    font-size: 12px;
}

#general_product_page .hvr-ripple-out{
    line-height: 25px;
}

/* Paginator */
#general_product_page .portfolio-navigation {
    margin: 0 !important;

}
#general_product_page .gem-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
}
/* Normal state */
#general_product_page .portfolio-navigator a {
    display: inline-block;
    padding: 5px 10px;
    background-color: white;
    color: #99a9b5;
    border: 1px solid #3c3950;
    text-decoration: none;
    font-size: 14px;
    box-sizing: content-box;
    margin: 0px 8px 16px;
  }
  
  /* Current state */
  #general_product_page .portfolio-navigator a.current {
    background-color: #3c3950;
    color: white;
  }
  
  /* Hover state */
  #general_product_page .portfolio-navigator a:hover {
    background-color: #99cc33;
    color: white;
  }
  .gem-pagination .next i.default:before {
    content: '>';
    color: #abb8c0;
}
.gem-pagination .prev i.default:before {
    content: '<';
    color: #abb8c0;
}
  

@media (min-width: 1212px) {
    #section-default-content .container {
        width: 1212px;
    }
}

@media (min-width: 768px) {
    .vc_col-sm-9 {
        width: 75%;
        float: right;
    }
}


