	/* Globals */

:root {
	--font-family-default: 'Montserrat', sans-serif;
	--font-weight-black: 900;
	--font-weight-extrabold: 800;
	--font-weight-bold: 700;
	--font-weight-semibold: 600;
	--font-weight-medium: 500;
	--font-weight-regular: 400;
	--font-weight-light: 300;
	--font-weight-extralight: 200;
	--font-weight-thin: 100;
	--section-width-full: 100%;
	--section-width-wider: calc(100vw * 1800/1900);
	--section-width-wide: calc(100vw * 1400/1900);
	--section-width-boxed: calc(100vw * 1200/1900);
	--transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
	--color-mrt-green: #99CC33;
}

/* button effect */
.hvr-ripple-out {
    display: inline-block;
    min-width: 250px;
    font-size: 16px;
    letter-spacing: 3px;
    border: 1px solid #99cc33;
    background-color: rgba(1, 6, 10, 0.65);
    color: #99cc33;
    padding: 12px 20px;
    text-align: center;
    outline: 1px solid;
    outline-offset: -2px;
    text-shadow: none;
    -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
    cursor: pointer;
}
.hvr-ripple-out.green-btn {
	background-color: #99cc33;
	color: white;
}
.hvr-ripple-out.small {
	min-width: auto;
	letter-spacing: 1px;
	padding: 5px 10px;
	font-size: 12px;
}
.hvr-ripple-out:before {
	border: none !important;
}
.hvr-ripple-out:hover {
	outline-color: rgba(255, 255, 255, 0);
	outline-offset: 15px;
}

/* Scrollbar */
::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-thumb {
	background: rgba(153, 204, 51, 0.75);
}

::-webkit-scrollbar-track {
	background: #01060a;
}

*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

span,
button,
a {
	-webkit-tap-highlight-color: transparent;
	/* Removes the highlight on tap */
}

h1 {
	text-transform: uppercase;
	text-align: center;
}

h1:first-child {
    margin-top: -10px;
}

body {
	font-family: var(--font-family-default);
	background-color: #000000;
	color: #ffffff;
	scroll-behavior: smooth;
	margin: 0;
}

a {
	color: var(--color-mrt-green);
	text-decoration: none;
}

h2,
.title-h2 {
	text-transform: uppercase;
	margin: 1.2em 0;
	letter-spacing: 0.05em;
}

h3, .title-h3 {
	text-transform: uppercase;
	margin: 1.13em 0;
	letter-spacing: .05em;
}

img {
	vertical-align: middle;
	width: 100%;
}

.d-none {
	display: none;
}

b, strong {
	font-weight: 700;
}

.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
	display: table;
	content: "";
}

.row {
	display: flex;
	width: 100%;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 0;
}

p {
	font-size: 12px;
	font-weight: 300;
	color: white;
	line-height: 24px;
	letter-spacing: 1px;
	position: relative;
}

p {
	margin: 0 0 1.1em;
}

.col {
	width: auto;
}

.col-10 {
	width: 10%;
}

.col-15 {
	width: 15%;
}

.col-20 {
	width: 20%;
}

.col-25 {
	width: 25%;
}

.col-30 {
	width: 30%;
}

.col-33 {
	width: 33.33%;
}

.col-35 {
	width: 35%;
}

.col-40 {
	width: 40%;
}

.col-45 {
	width: 45%;
}

.col-50 {
	width: 50%;
}

.col-60 {
	width: 60%;
}

.col-67 {
	width: 67%;
}

.col-70 {
	width: 70%;
}

.col-75 {
	width: 75%;
}

.col-80 {
	width: 80%;
}

.col-90 {
	width: 90%;
}

.col-100 {
	width: 100%;
}

.desktop {
	display: block;
}

.container {
	padding-left: 0;
	padding-right: 0;
	margin-right: auto;
	margin-left: auto;
}

.show {
	display: block;
}

.hide,
.hidden,
.mobile {
	display: none !important;
}

.break {
	flex-basis: 100%;
	height: 0;
}

.gradient-bg {
	background: linear-gradient(180deg, rgba(97, 98, 99, 0.3) 5.08%, #000000 70%);
}

.gradient-bg-rev {
	background: linear-gradient(0deg, #2B2B2B 13.55%, #000000 91.84%);
}

.gradient-bg-light {
	background: linear-gradient(0deg, #777777 13.55%, #1C1C1C 91.84%);
}

.anchored {
	position: relative;
}
/* 
.anchored .anchor {
	position: absolute;
	top: calc(-1 * (100vw * 200/1900));
} */

.anchored .anchor {
    position: absolute;
    top: -300px;
}

.sticky {
	position: fixed;
	width: 100%;
	left: 0;
	top: 115px;
	z-index: 100;
	border-top: 0;
}

.full {
	width: var(--section-width-full);
}

.wider {
	width: var(--section-width-wider);
}

.wide {
	width: var(--section-width-wide) !important;
}

.boxed {
	width: var(--section-width-boxed);
}

.btn-primary {
	font-size: calc(100vw * 17/1900);
	font-weight: 600;
	padding: calc(100vw * 15/1900) calc(100vw * 45/1900);
	display: inline-block;
	color: black;
	background-color: #99cc33;
	border: calc(100vw * 1/1900) solid #99cc33;
	transition: var(--transition);
}

.btn-primary:hover {
	color: #99cc33;
	background-color: transparent;
	transition: var(--transition);
}

.btn-secondary {
	font-weight: 600;
	font-size: calc(100vw * 17/1900);
	line-height: calc(100vw * 21/1900);
	text-align: center;
	color: #000000;
	background-color: white;
	border: calc(100vw * 1/1900) solid white;
	width: 100%;
	display: block;
	padding: calc(100vw * 15/1900);
}

.btn-secondary:hover {
	color: white;
	background-color: transparent;
	transition: var(--transition);
}

.hollow {
	letter-spacing: normal;
	color: #000000;
	background: white;
	background-clip: text;
	-webkit-text-stroke: 1px transparent;
}

#included-files-fie-on-wp-footer {
	display: none !important;
}
.section-mrt-blocks, .section-mrt-blocks .info-holder {
	position: relative;
}

.section-mrt-blocks .container.fullwidth .info-holder {
	padding-left: 15%;
	padding-right: 15%;
  }

.section-mrt-blocks .col-100 {
    width: 100%;
}

.section-mrt-blocks .col-60 {
    width: 60%;
}

.section-mrt-blocks .container {
	padding: 5% 0;
}

.section-mrt-blocks .container.fullwidth {
	width: 100%;
	padding: 0;
}

.section-mrt-blocks .mrt-row {
	display: flex;
	width: 100%;
	align-items: center;
  }

.bg-clear {
    background-color: transparent !important;
    box-shadow: none !important;
}
.info-holder {
    padding: 35px;
    background-color: rgba(1, 6, 10, 0.75);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}

input[type="text"],
input[type="password"],
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="number"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="month"],
input[type="week"],
textarea,
.combobox-wrapper,
#page .woocommerce .select2-container .select2-selection--single,
.select2-container .select2-dropdown,
.chosen-container-single .chosen-single,
.select2-container .select2-choice,
select.wpcf7-form-control.wpcf7-select,
.thegem-combobox-wrap .thegem-combobox__trigger,
.thegem-combobox-wrap .thegem-combobox__options,
.product-page__wrapper .product-page__nav .product-page__nav-preview,
.product-page__wrapper form.cart .quantity,
.gem-attribute-selector .gem-attribute-options li,
table.variations .thegem-select {
	border: 1px solid #dfe5e8;
}

input[type="text"],
input[type="password"],
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="number"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="month"],
input[type="week"],
textarea,
select,
.chosen-container-single .chosen-single,
.select2-container .select2-choice,
.widget_product_search form:before {
	color: #3c3950;
}

input[type="text"],
input[type="password"],
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="number"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="month"],
input[type="week"],
textarea,
.chosen-container,
.select2-container,
.checkbox-sign,
.radio-sign {
	background-color: #f4f6f7;
}

input[type="text"],
input[type="password"],
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="number"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="month"],
input[type="week"] {
	padding: 0 10px;
	max-width: 100%;
	vertical-align: middle;
}

input,
button,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

input {
	line-height: normal;
}

button,
input,
optgroup,
select,
textarea {
	color: inherit;
	font: inherit;
	margin: 0;
	outline: 0 none;
}

button {
	cursor: pointer;
}
.col-xs-1,
	.col-sm-1,
	.col-md-1,
	.col-lg-1,
	.col-xs-2,
	.col-sm-2,
	.col-md-2,
	.col-lg-2,
	.col-xs-3,
	.col-sm-3,
	.col-md-3,
	.col-lg-3,
	.col-xs-4,
	.col-sm-4,
	.col-md-4,
	.col-lg-4,
	.col-xs-5,
	.col-sm-5,
	.col-md-5,
	.col-lg-5,
	.col-xs-6,
	.col-sm-6,
	.col-md-6,
	.col-lg-6,
	.col-xs-7,
	.col-sm-7,
	.col-md-7,
	.col-lg-7,
	.col-xs-8,
	.col-sm-8,
	.col-md-8,
	.col-lg-8,
	.col-xs-9,
	.col-sm-9,
	.col-md-9,
	.col-lg-9,
	.col-xs-10,
	.col-sm-10,
	.col-md-10,
	.col-lg-10,
	.col-xs-11,
	.col-sm-11,
	.col-md-11,
	.col-lg-11,
	.col-xs-12,
	.col-sm-12,
	.col-md-12,
	.col-lg-12 {
		position: relative;
		min-height: 1px;
		padding-left: 21px;
		padding-right: 21px;
	}

	.col-sm-1,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12 {
		float: left;
	}

#sizes .title-holder h2,
#options .title-holder h2,
#accessories .title-holder h2,
#match .title-holder h2,
#gallery .title-holder h2,
#brands .title-holder h2 {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	font-size: calc(100vw * 240/1900);
	line-height: 1.1;
	letter-spacing: 0;
	color: rgba(255, 255, 255, 0.1);
	margin-top: 0;
	margin-bottom: 0;
	padding: 0;
}

@media only screen and (max-width: 1211px) {
    .section-mrt-blocks .container.fullwidth .info-holder {
        padding: 0 5% !important;
    }
}

@media (min-width: 1212px) {
	.container {
			width: 1212px;
		}
	}
/* Mobile Devices */

@media only screen and (max-width: 450px) {

	.section-mrt-blocks .col-33,
	.section-mrt-blocks .col-45,
	.section-mrt-blocks .col-50,
	.section-mrt-blocks .col-55 {
		width: 100%;
	}
}

@media only screen and (max-width: 765px) {
	.margin-top {
		margin-top: 24px;
	}
}

@media (min-width: 768px) {
	.col-sm-12 {
		width: 100%;
	}
}

@media only screen and (max-width : 599px) {
	.desktop {
		display: none;
	}

	.mobile {
		display: block !important;
	}

	.row {
		flex-direction: column;
	}

	.col {
		width: 100% !important;
	}

	.wider,
	.wide,
	.boxed {
		width: calc(100% - 30px);
		margin: 0 auto;
	}

	.btn-primary {
		font-size: 16px;
		padding: 10px 25px;
		border: 1px solid #99cc33;
	}

	.btn-secondary {
		font-size: 16px;
		line-height: 20px;
		border: 1px solid white;
		padding: 10px;
	}

	.prevSlide {
		left: -20px !important;
	}

	.nextSlide {
		right: -20px !important;
	}
	.section-mrt-blocks .mrt-row {
		flex-direction: column;
	}
}