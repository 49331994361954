#section-2 {
	padding: calc(100vw * 100/1900) 0;
	background: #fff;
}
#section-2 .row {
	align-items: center;
}
#section-2 .img-holder {
	text-align: center;
}
#section-2 .img-holder img{
	width: 80%;
}
#section-2 .content-holder {
	padding: 0 calc(100vw * 80/1900);
}
#section-2 .content-holder .title {
	color: #000;
    font-family: Montserrat;
    font-size: calc(100vw * 32/1900);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
	text-transform: capitalize;
}
#section-2 .content-holder .desc {
	font-weight: 400;
	font-size: calc(100vw * 16/1900);
	line-height: calc(100vw * 26/1900);
	text-align: justify;
	letter-spacing: 0.03em;
	color: #000;
}



@media only screen and ( max-width: 765px ) {	
#section-2 .content-holder .title {
    text-align: center;
    font-size: calc(100vw * 24/390);
}
#section-2 .content-holder .title img {
    width: 80%;
}
#section-2 .content-holder .desc {
    font-size: 14px;
    line-height: 24px;
}
}