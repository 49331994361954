#mrt-footer {
    background-color: #000000;
    padding: 55px 20px;
    border-top: 1px solid #313646;
}

/* #mrt-footer img {
    width: auto;
} */
#mrt-footer h4.footer-title {
  	color: #99cc33;
    line-height: 1em;
    font-size: 16px;
    
}
#mrt-footer p {
    font-size: 12px;
  	color: white;
    margin-bottom: 35px;
}
#mrt-footer ul {
    padding: 0;
}
#mrt-footer ul li {
    list-style-type: none;
    line-height: 25px;
}
#mrt-footer a {
    color: white;
  	font-weight: bold;
    font-size: 12px;
    letter-spacing: 1px;
}
#mrt-footer a:hover {
    color: #99cc33;
}
.mrt-social-media {
    padding: 0 5px;
    min-width: 25px;
    height: 25px;
    display: inline-block;
    text-align: center;
    margin: 0 5px 0 0;
  	cursor: pointer;
}
.mrt-social-media:hover {
    color: white !important;
}
@media (min-width: 1212px){

    #mrt-footer .container {
        width: 1212px;
    }

}


@media (min-width: 768px) {
    .col-sm-3 {
        width: 25%;
    }
}
