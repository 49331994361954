#section-seven .title {
    font-weight: 800;
    font-size: calc(100vw* 84 / 1900);
    line-height: calc(100vw* 76 / 1900);
    color: #FFFFFF;
    margin: 0 0 calc(100vw* 50 / 1900);
    text-align: center;
}

#section-seven .transparent {
    color: #000000;
    background: #FFFFFF;
    -webkit-background-clip: text;
    -webkit-text-stroke: calc(100vw* 3 / 1900) transparent;
}

/* .section-mrt-blocks{
    margin: 0 30.781px;
} */
.section-mrt-blocks .mrt-row {
    display: flex;
    width: 100%;
    align-items: center;
    
}
.image-holder {
    text-align: center;
}

.fa-light.fa-circle-play.cust-fa {
    margin-left: calc(100vw* -510 / 1900);
    margin-top: calc(100vw* 200 / 1900);
    font-size: calc(100vw* 150 / 1900);
}
i.fa-light.fa-circle-play {
    color: rgba(255, 255, 255, 1);
    position: absolute;
    margin-left: calc(100vw* -280 / 1900);
    font-size: calc(100vw* 100 / 1900);
    margin-top: calc(100vw* 75 / 1900);
    font-weight: 300;
}