#section-toolboxes-1 {
	background: linear-gradient(180deg, #121212 0%, rgba(255, 255, 255, 0.2) 40%, rgba(255, 255, 255, 0.2) 80%, #121212 100%);
	padding: calc(100vw * 100/1900) 0;
}
#section-toolboxes-1 .row {
	align-items: center;
}
#section-toolboxes-1 .img-holder {
	text-align: center;
}
#section-toolboxes-1 .img-holder img{
	width: 80%;
}
#section-toolboxes-1 .content-holder {
	padding: 0 calc(100vw * 80/1900);
}
#section-toolboxes-1 .content-holder .title {
    font-family: Montserrat;
    font-size: calc(100vw * 32/1900);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
	text-transform: capitalize;
}
#section-toolboxes-1 .content-holder .desc {
	font-weight: 400;
	font-size: calc(100vw * 16/1900);
	line-height: calc(100vw * 26/1900);
	text-align: justify;
	letter-spacing: 0.03em;
}

@media only screen and (max-width: 765px) {
    #section-toolboxes-1 .img-holder img {
		width: 100%;
	}
	#section-toolboxes-1 .content-holder .title {
		font-size: calc(100vw * 22/390);
		text-align: center;
	}
	#section-toolboxes-1 .content-holder .desc {
		font-size: calc(100vw * 14/390);
		line-height: calc(100vw * 24/390);
	}

}
