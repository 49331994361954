#mrt-adventure {
    height: calc(100vw* 609 / 1900);
    background: linear-gradient(180deg, #121212 0%, rgba(255, 255, 255, 0.2) 40%, rgba(255, 255, 255, 0.2) 80%, #121212 100%);
}

#mrt-adventure h2.title {
    text-align: left;
    padding-left: 0;
    color: #FFF;
    font-family: Montserrat;
    font-size: calc(100vw * 80/1900);
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin: calc(100vw* 55 / 1900) 0 !important;
}

.swiper{
    overflow: unset!important;
}

#mrt-adventure h2.title .transparent {
    letter-spacing: normal;
    color: #121212;
    background: #99CC33;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-stroke: calc(100vw* 3 / 1900) transparent;
}

.swiper-slide {
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.75);
}

.swiper-pagination-bullet-active {
    visibility: hidden;
}

@media only screen and (max-width: 690px) {
   #mrt-adventure .swiper-slide {
        flex-shrink: 1 !important;
        margin: 24px 8px 0 0;
        height: auto !important;
    }
}

@media only screen and (max-width: 765px) {
    #mrt-adventure h2.title {
        font-size: calc(100vw * 75/590);
        text-align: center;
        margin: 0;
        padding: calc(100vw* 24 / 590) 0;
    }
    #mrt-adventure h2.title .transparent {
        -webkit-text-stroke: calc(100vw* 3 / 590) transparent;
    }

}

@media only screen and (max-width: 599px) {
    #mrt-adventure {
        height: calc(100vw* 400 / 390);
    }
    #mrt-adventure h2.title {
        font-size: calc(100vw* 58 / 590) !important;
        text-align: center;
        margin: 0;
        padding: calc(100vw* 24 / 590) 0;
    }
}

@media only screen and (max-width: 765px) {
    .mySwiper .swiper-slide img {
        width: 225px ;
        height: 150px ;
        object-fit: cover;
    }
}
