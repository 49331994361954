.image-right-background {
    background-image: url('/src/components/media/plainbackground.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}





.image-right-background .container.fullwidth {
  width: 100%;
}

.image-right-background .container.fullwidth .info-holder {
  background-color: transparent;
  box-shadow: none;
}
.image-right-background .container.fullwidth.text-align-right .info-holder {
  padding-left: 5%;
  padding-right: 5%;
  max-width: 550px;
  float: right;
  text-align: right;
}

.image-right-background .info-holder .mrt-title {
  font-size: 54px;
  color: white;
  margin: 0;
  line-height: 1em;
  font-weight: 900;
  letter-spacing: 3px;
  margin-bottom: 35px;
}
.image-right-background .text-align-right .info-holder .mrt-title {
  text-align: right;
}

.image-right-background .text-align-right .mrt-desc {
  font-size: 12px;
  font-weight: 300;
  color: white;
  padding-left: 100px;
  line-height: 24px;
  letter-spacing: 1px;
  position: relative;
  margin-bottom: 35px;
}


/* button */


/* media queries */
@media only screen and (max-width: 765px) {
  .image-right-background .container.fullwidth.text-align-right .info-holder {
    float: none;
    text-align: left;
    max-width: 380px;
  }
  .image-right-background .text-align-right .info-holder .mrt-title {
    font-size: 36px;
    text-align: left;
    
  }
  .image-right-background .text-align-right .mrt-desc {
    border-style: solid none none none;
    border-top-color: #99cc33;
    border-width: 1px;
    padding-top: 10px;
    font-size: 12px;
    font-weight: 300;
    color: white;
    padding-left: 0px;
    line-height: 24px;
    letter-spacing: 1px;
    position: relative;
    margin-bottom: 35px;

  }
  .image-right-background .mrt-row {
    flex-direction: column;
  }

  .section-mrt-blocks .col-33, .section-mrt-blocks .col-45, .section-mrt-blocks .col-50, .section-mrt-blocks .col-55 {
    width: 100%;
  }
  .image-right-background .hvr-ripple-out {
    margin-bottom: 55px;
  }
}