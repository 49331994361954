/* UpperIcons css */
#section-toolboxes-uppericons .container,
.section-tray-uppericons .container {
    width: 100%;
    padding: 0 !important;
}

#section-canopy-uppericons,
#section-tray-uppericons,
#section-toolboxes-uppericons {
    background-color: #16191C;
}

#section-canopy-uppericons .uppericons-wrapper,
#section-tray-uppericons .uppericons-wrapper,
#section-toolboxes-uppericons .uppericons-wrapper,
.section-tray-uppericons .uppericons-wrapper {
    padding: 15px;
    text-align: center;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
}

#section-canopy-uppericons .uppericon,
#section-tray-uppericons .uppericon,
#section-toolboxes-uppericons .uppericon,
.section-tray-uppericons .uppericon {
    display: inline-block;
    max-width: 155px;
    margin: 0 12px;
    text-align: center;
    opacity: 0.75;
    vertical-align: top;
}

#section-canopy-uppericons .uppericon p,
#section-tray-uppericons .uppericon p,
#section-toolboxes-uppericons .uppericon p,
.section-tray-uppericons .uppericon p {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 8px;
    line-height: 1em;
    color: white;
}

#section-canopy-uppericons .uppericon:hover,
#section-tray-uppericons .uppericon:hover,
#section-toolboxes-uppericons .uppericon:hover,
.section-tray-uppericons .uppericon:hover {
    transform: scale(1.1);
    transition: transform ease-in-out 0.2s;
    opacity: 1;
}

#section-tray-uppericons .uppericon p,
#section-toolboxes-uppericons .uppericon p,
.section-tray-uppericons .uppericon p {
    margin-top: 15px;
}