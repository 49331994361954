#features .container {
    padding: 0;
    width: 100%;
}
#features .mrt-row {
    align-items: start;
} 
#features > div.container.fullwidth > div.mrt-row.gradient-bg-rev > div > div {
    margin-top: calc(-100vw * 70/1900);
  }

#features .info-holder {
    padding-left: 25%;
    padding-right: 5%;
}
#features .info-holder .title {
    font-weight: 900;
    font-size: calc(100vw * 44/1900);
    border-left: calc(100vw * 12/1900) solid #99CC33;
    padding-left: calc(100vw * 80/1900);
    margin: 1.13em 0;
}

#features .info-holder .list {
    padding-left: 0;
    list-style: none;
}
#features .info-holder .list .list-item {
    font-size: calc(100vw * 16/1900);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: calc(100vw * 14/1900) 0;
    position: relative;
}
#features .info-holder .list .list-item span {
    display: inline-block;
}

#features .green {
    background: #99CC33;
    box-shadow: 4px 4px 50px 15px rgba(0, 0, 0, 0.7);
    min-height: 25vw;
    display: flex;
    align-items: center;
    z-index: 1;
}
#features .green .info-holder {
padding: 35px 55px;
}
#features .green .info-holder img {
    width: calc(100vw * 85/1900);
}
#features .green .info-holder h3 {
    font-weight: 700;
    font-size: calc(100vw * 32/1900);
    color: white;
    line-height: 1.1;
    text-transform: none;
    margin: 0;
}

#features .green + .mrt-col > .image-holder {
    min-height: 25vw;
    background-image: url(../../media/UteCanopy/structural-warranty-image-for-ute-canopy.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: black;
    position: relative;
}

#features .green + .mrt-col > .image-holder:before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
}

#features .second .image-holder:before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
}

#features .second .image-holder {
    min-height: 25vw;
    background-image: url(../../media/UteCanopy/Manufacturing-Difference-image-for-ute-canopy.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: black;
    position: relative;
}
#features .white {
    background: white;
    min-height: 25vw;
    display: flex;
    align-items: center;
    z-index: 1;
}
#features .white .info-holder {
    padding: calc(100vw * 30/1900) calc(100vw * 20/1900);
}
#features .white .info-holder h3, #features .white .info-holder ul {
    color: #636363;
}

#features .white .info-holder h3 {
    font-weight: 700;
    font-size: calc(100vw * 28/1900);
    color: #636363;
    line-height: 1.1;
    text-transform: none;
    margin: 0 0 calc(100vw* 20 / 1900);
}

#features .white .info-holder ul {
    font-size: calc(100vw * 15/1900);
    line-height: 1.5;
}
#features .white .info-holder ul li {
    margin-bottom: calc(100vw * 10/1900);
}

#features .primary .image-holder,
.product_cat-ute-canopies #features .primary .image-holder {
    min-height: 50vw;
    background-image: url(../../media/UteCanopy/mrt-aluminium-ute-canopy-1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: black;
    position: relative;
}


@media only screen and (max-width: 1200px) {
    #features .green .info-holder h3, #features .white .info-holder h3 {
        font-size: 28px !important;
    }
}

@media (max-width: 820px) {
    #features .info-holder .list .list-item {
        font-size: 16px!important;
    }
    #features .info-holder .title {
        font-size: 44px !important;
    }
    #features .white .info-holder ul {
        font-size: 12px !important;
    }
    #features .primary .image-holder {
        min-height: 100vw !important;
    }
}

@media only screen and (max-width: 498px) {
    #features .green,
    #features .green+.mrt-col>.image-holder,
    #features .white,
    #features .second .image-holder {
        min-height: 100vw!important;
    }
    #features .green .info-holder img {
        width: 85px;
    }
}