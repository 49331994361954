#sec-nav .section-nav {
    background-color: #99CC33;
    padding: calc(100vw * 12/1900);
    background-size: cover;
    background-position: center;
}

.section-nav .content-holder {
    display: flex;
    justify-content: space-evenly;
    max-width: 1212px;
    margin: 0 auto;
    font-size: 21px;
    color: #000000;
    font-weight: 600;
}

.section-nav .content-holder a {
    font-size: 16px;
    color: #000;
}

.sticky {
    position: fixed;
    width: 100%;
    left: 0;
    top: 120px;
    z-index: 100;
    border-top: 0;
}